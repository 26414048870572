import React, { useEffect } from 'react';
import { getCurrentUser, getCurrentUserApplications } from '../../app/currentUserSlice';
import { useAppDispatch } from '../../app/hooks';
import authService from '../../services/AuthService';

export default function SilentRenew() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        authService.signinSilentCallback()
            .then(function () {
                dispatch(getCurrentUser());
                dispatch(getCurrentUserApplications());
            })
            .catch(function (e) {
            });
    }, []);

    return (
        <React.Fragment></React.Fragment>
    );
}
