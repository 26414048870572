export interface OidcState {
    pathname: string
}

type GUID = string & { isGuid: true };

export function guid(guid: string): GUID {
    const pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    return pattern.test(guid) === true
        ? guid as GUID
        : "00000000-0000-0000-0000-000000000000" as GUID;
}

export enum AuditLogType {
    None = 0,
    Create = 10,
    Update = 20,
    Delete = 30,
    EnableUser = 40,
    DisableUser = 50,
    UnlockUser = 60,
    UpdateUserName = 70,
    UpdateEmail = 80,
    ResetUserPassword = 90,
    ForceUserPasswordReset = 100,
    ChangeUserPassword = 110,
    ApplicationEnabled = 120,
    ApplicationDisabled = 130,
    SignIn = 140,
    SignOut = 150,
    Impersonate = 160,
    StopImpersonating = 170
}

export enum ContactType {
    Undefined = 0,
    Phone = 1,
    Mobile = 2,
    Fax = 3,
    Email = 4
}

export const ContactTypeLabels: {
    value: string | number,
    label: string
}[] = [
        { value: 0, label: "-" },
        { value: 1, label: "Phone" },
        { value: 2, label: "Mobile" },
        { value: 3, label: "Fax" },
        { value: 4, label: "Email" }
    ];

export enum AddressType {
    Default = 1
}

export enum FieldDefinitionType {
    "string",
    "Date",
    "DateTime",
    "number",
    "enum",
    "boolean",
    "GUID",
    "Country",
    "User"
}

export enum Direction {
    Ascending,
    Descending
}

export enum Operator {
    Equal = 0,
    NotEqual,
    LessThan,
    GreaterThan,
    LessThanOrEqual,
    GreaterThanOrEqual = 5,
    Contains,
    NotContains,
    BeginsWith,
    NotBeginsWith,
    EndsWith = 10,
    NotEndsWith,
    In,
    NotIn,
    Between,
    NotBetween = 15,
    Today,
    Tomorrow,
    ThisPastWeek,
    ThisUpcomingWeek,
    ThisPastMonth,
    ThisUpcomingMonth,
    ThisPastQuarter,
    ThisUpcomingQuarter
}

export interface FieldDefinition<T> {
    /**
     * keyof T - Represents the name of the field on object T. T[fieldName] should return the value this definition is representing
     */
    fieldName: keyof T,
    /**
     * Represents the datatype of this field
     */
    type: FieldDefinitionType,
    /**
     * Setting filterable to true will enable this field to be a filter in pagination
     */
    filterable: boolean,
    /**
     * The name of this field, formatted to be displayed to the user
     */
    displayName: string,
    /**
     * If the type of this field is FieldDefinitionType.enum this array will facilitate a components resoltion of labels and dropdowns
     */
    enumOptions?: { value: number, label: string }[],
    /**
     * Legacy setting - Setting this to true would make this a 'primary' filter - consider removing
     */
    primary?: boolean,
    /**
     * Optional setting - setting this to true will enter this field in the sortable dropdown. Leaving it undefined is the same as being false
     */
    sortable?: boolean,
    /**
     *  Optional setting - setting this to true will enter this field in the list of columns available on a table display. Leaving it undefined is the same as being false
     */
    columnable?: boolean
}

export interface Filter<T> {
    field: keyof T,
    operator: Operator,
    arg1: string,
    arg2?: string,
    displayName?: string
}

export interface Sort<T> {
    sortBy: keyof T,
    sortDirection: Direction
}

export interface Page {
    pageNumber: number,
    pageSize: number
}

export interface PaginationRequest<T> {
    sort: Sort<T>
    //filters: Filter<T>[],
    page: Page,
    filterString: string,
    //loaded: boolean
}

export interface PaginationResponse<T> {
    pageNumber: number,
    totalCount: number,
    items: T[]
}

export interface ApiViewResponseError {
    detail: string,
    code: string,
    id: string,
    title: string
}

export interface ApiViewResponseResource {
    id: string,
    type: string,
    data: string | number | symbol | object
}

export interface ApiViewResponse {
    resource: ApiViewResponseResource[],
    errors: ApiViewResponseError[],
    id: string
}

export interface ApiViewEntityResponse<T> extends ApiViewResponse {
    data: T
}

export interface UserContactDataModel {
    contact: string,
    contactType: ContactType,
    phoneExtension: string
}

export interface UserContactModel extends UserContactDataModel {
    id: number
}

export interface AddressDataModel {
    street1: string,
    street2: string,
    city: string,
    state: string,
    postalCode: string,
    countryAlpha3Code: string
}

export interface AddressModel extends AddressDataModel {
    id: number
}

export interface UserAddressModel {
    id: number,
    addressType: AddressType,
    address: AddressModel
}

export interface UserClientModel {
    clientId: number
}

export interface UserListItemModel {
    id: number,
    userName: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    enabled: boolean
}

export interface UserValidationDataModel {
    userName: string,
    email: string,
    phoneNumber: string
}

export interface UserPersonalDetailsModel {
    firstName: string,
    lastName: string,
    phoneNumber: string
}

export interface UserProfileDataModel extends UserPersonalDetailsModel {
    userName: string,
    email: string,
    enabled: boolean,
    twoFactorEnabled: boolean,
    forcePasswordReset: boolean
}

export interface UserDataModel extends UserProfileDataModel {
    contacts: UserContactModel[],
    userAddresses: UserAddressModel[],
    userClients: UserClientModel[]
}

export interface UserModel extends UserProfileDataModel {
    id: number,
    externalId: string,
    emailConfirmed: boolean,
    phoneNumberConfirmed: boolean,
    twoFactorEnabled: boolean,
    lockoutEnabled: boolean,
    accessFailedCount: number,
    contacts: UserContactModel[],
    userAddresses: UserAddressModel[],
    userClients: UserClientModel[]
}

export interface CurrentUserModel extends UserModel {
    roles: string[]
}

export interface UserProfileResponseModel extends UserModel {
    password: string
}

export interface UserPasswordModel {
    id: number,
    userName: string,
    firstName: string,
    lastName: string,
    password: string
}

export interface CountryModel {
    countryAlpha3Code: string,
    name: string
}

export interface ApplicationModel {
    id: number,
    clientId: string,
    clientName: string,
    description: string,
    clientUri: string,
    logoUri: string
}

export interface AuditLogUser {
    id: number,
    userName: string,
    firstName: string,
    lastName: string
}

export interface AuditLog {
    id: number,
    entityId: string,
    entityType: string,
    rootEntityId: string,
    rootEntityType: string,
    user: AuditLogUser,
    action: AuditLogType,
    actionLabel: string,
    timestamp: string
}

export interface AuditLogDetails extends AuditLog {
    newValues: { [key: string]: object },
    oldValues: { [key: string]: object }
}

export const AuditLogFields: FieldDefinition<AuditLog>[] = [
    {
        fieldName: "id",
        type: FieldDefinitionType.GUID,
        filterable: false,
        displayName: "Id"
    },
    {
        fieldName: "entityId",
        type: FieldDefinitionType.string,
        filterable: true,
        displayName: "Entity Id"
    },
    {
        fieldName: "entityType",
        type: FieldDefinitionType.string,
        filterable: false,
        displayName: "Entity Type"
    },
    {
        fieldName: "rootEntityId",
        type: FieldDefinitionType.string,
        filterable: true,
        displayName: "Root Entity Id"
    },
    {
        fieldName: "rootEntityType",
        type: FieldDefinitionType.string,
        filterable: false,
        displayName: "Root Entity Type"
    },
    {
        fieldName: "user",
        type: FieldDefinitionType.User,
        filterable: false,
        displayName: "User"
    },
    {
        fieldName: "actionLabel",
        type: FieldDefinitionType.string,
        filterable: true,
        displayName: "Action"
    },
    {
        fieldName: "timestamp",
        type: FieldDefinitionType.DateTime,
        filterable: true,
        displayName: "Timestamp"
    }
]

export const UserFields: FieldDefinition<UserModel>[] = [
    {
        fieldName: "id",
        type: FieldDefinitionType.number,
        filterable: false,
        displayName: "Id"
    },
    {
        fieldName: "userName",
        type: FieldDefinitionType.string,
        filterable: false,
        displayName: "User Name",
        primary: false
    },
    {
        fieldName: "firstName",
        type: FieldDefinitionType.string,
        filterable: false,
        displayName: "First Name",
        primary: false
    },
    {
        fieldName: "lastName",
        type: FieldDefinitionType.string,
        filterable: false,
        displayName: "Last Name",
        primary: false
    },
    {
        fieldName: "email",
        type: FieldDefinitionType.string,
        filterable: false,
        displayName: "Email",
        primary: false
    },
    {
        fieldName: "phoneNumber",
        type: FieldDefinitionType.string,
        filterable: false,
        displayName: "Phone",
        primary: false
    },
    {
        fieldName: "enabled",
        type: FieldDefinitionType.boolean,
        filterable: false,
        displayName: "Enabled",
        primary: false
    }
]
