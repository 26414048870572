import {
    createSlice, createAsyncThunk, isRejectedWithValue, isPending, PayloadAction
} from '@reduxjs/toolkit';
import {
    ApiViewEntityResponse, ApiViewResponseError, PaginationResponse,
    Direction, UserListItemModel, PaginationRequest
} from '../typings'
import { getBodyErrors } from '../services/ApiService';
import identityService from '../services/IdentityService';
import { Message, MessageLevel } from '@lp/lp-ui';

interface UsersState {
    loading: boolean,
    data: PaginationResponse<UserListItemModel>,
    messages: Message[],
    pagination: PaginationRequest<UserListItemModel>
}

const defaultPagination: PaginationRequest<UserListItemModel> = {
    sort: {
        sortBy: 'userName',
        sortDirection: Direction.Ascending
    },
    page: {
        pageSize: 10,
        pageNumber: 0
    },
    filterString: ''
}

const initialState: UsersState = {
    loading: false,
    data: {
        pageNumber: 0,
        totalCount: 0,
        items: []
    },
    messages: [],
    pagination: defaultPagination
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        paginationSelected: (state, action: PayloadAction<PaginationRequest<UserListItemModel>>) => {
            state.pagination = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(usersFilter.fulfilled, (state, action) => {
                state.loading = false;
                state.messages = [];
                state.data = action.payload.data;
            })
            .addMatcher(isApiPending, (state) => {
                state.loading = true;
            })
            .addMatcher(isApiRejected, (state, action) => {
                state.loading = false;
                if (action.payload) {
                    state.messages = action.payload.map(e => ({ details: e.detail, level: MessageLevel.Error }));
                }
            })
    }
});

export const usersFilter = createAsyncThunk<ApiViewEntityResponse<PaginationResponse<UserListItemModel>>, PaginationRequest<UserListItemModel>, { rejectValue: ApiViewResponseError[] }>
    ('users/filter', async (pagination: PaginationRequest<UserListItemModel>, { rejectWithValue }) => {
        try {
            const response = await identityService.filterUsers(pagination);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

const isApiPending = isPending(usersFilter);
const isApiRejected = isRejectedWithValue(usersFilter);

export const {
    paginationSelected,
} = usersSlice.actions

export default usersSlice.reducer
