import React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { EmailSchema } from '../../validationSchemes';
import { EntityEditDialog, TextInput } from '@lp/lp-ui';
import { updateEmail, UpdateEmailRequest } from '../../app/userSlice'
import { useAppDispatch } from '../../app/hooks';
import { useCurrentUser } from '../../app/useCurrentUser';

interface EmailModel {
    email: string
}

type EmailEditDialogProps = {
    open: boolean,
    userId: number,
    email: string,
    handleClose: () => void
}

const EmailEditDialog: React.FC<EmailEditDialogProps> = (props: EmailEditDialogProps) => {
    const dispatch = useAppDispatch();
    const [isAdmin, _] = useCurrentUser();

    const formik = useFormik<EmailModel>({
        initialValues: { email: props.email },
        enableReinitialize: true,
        validationSchema: EmailSchema,
        onSubmit: async (values) => {
            const request: UpdateEmailRequest = {
                userId: props.userId,
                email: values.email
            };
            const result = await dispatch(updateEmail(request));
            if (result.meta.requestStatus === 'fulfilled') {
                handlePopupClose();
                return true;
            }
            return false;
        }
    });

    const {
        errors,
        touched,
        getFieldProps,
        submitForm
    } = formik;

    const handlePopupClose = () => {
        formik.resetForm();
        props.handleClose();
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={submitForm}>
                    <EntityEditDialog
                        open={props.open}
                        editing={isAdmin}
                        maxWidth="sm"
                        fullWidth
                        title="Email"
                        handleClose={handlePopupClose}
                        handleSave={submitForm}
                        showMoreMenu={false}
                    >
                        <TextInput
                            isEditing={isAdmin}
                            required
                            type="string"
                            label="Email"
                            {...getFieldProps("email")}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />
                    </EntityEditDialog>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}

export default EmailEditDialog;
