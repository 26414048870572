import { useAppSelector } from './hooks';
import { ApplicationModel } from '../typings';
import { useEffect, useState } from 'react';

export function useCurrentUserApplications(): ApplicationModel[] {
    const currentUser = useAppSelector(s => s.currentUser);
    const [currentUserApplications, setCurrentUserData] = useState<ApplicationModel[]>([]);

    useEffect(() => {
        setCurrentUserData(currentUser?.applications !== undefined ? currentUser?.applications : []);
    }, [currentUser, currentUser.applications]);

    return currentUserApplications;
}
