import React from 'react';
import { Grid, Typography, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useCache from '../../app/useCache';
import { CachedEntity } from '../../app/cacheSlice';
import { userDataSelected } from '../../app/userSlice';
import { UserClientModel } from '../../typings';
import { useCurrentUser } from '../../app/useCurrentUser';

export default function UserApplicationsPanel() {
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(store => store.user);
    const applications = useCache(CachedEntity.Application);
    const [isAdmin, _] = useCurrentUser();

    const handleUserApplicationChange = (clientId: number, enabled: boolean) => {
        if (data !== null) {
            if (enabled) {
                const userClient: UserClientModel = {
                    clientId: clientId
                };
                const userClientsClone = Object.assign([], data.userClients);
                userClientsClone.push(userClient);
                dispatch(userDataSelected({ ...data, userClients: userClientsClone }));
            }
            else {
                const index = data.userClients.findIndex(c => c.clientId === clientId);
                if (index !== undefined && index > -1) {
                    const userClientsClone = Object.assign([], data.userClients);
                    userClientsClone.splice(index, 1);
                    dispatch(userDataSelected({ ...data, userClients: userClientsClone }));
                }
            }
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                        Applications
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        {applications.map((application) => (
                            <FormControlLabel
                                control={<Switch
                                    checked={data?.userClients.some(uc => uc.clientId === application.id)}
                                    onChange={(_, checked) => handleUserApplicationChange(application.id, checked)}
                                />}
                                label={application.label ? application.label : application.key}
                                key={application.key}
                                disabled={!isAdmin}
                            />
                        ))}
                    </FormGroup>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
