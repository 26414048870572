import { useAppSelector } from './hooks';
import { CurrentUserModel } from '../typings';
import { ADMIN_ROLE } from '../constants';
import { useEffect, useState } from 'react';

export function useCurrentUser(): [boolean, CurrentUserModel | null] {
    const currentUser = useAppSelector(s => s.currentUser);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [currentUserData, setCurrentUserData] = useState<CurrentUserModel | null>(null);

    useEffect(() => {
        const isAdminValue = currentUser !== null &&
            currentUser.data && currentUser.data.roles !== null
            ? currentUser.data.roles.indexOf(ADMIN_ROLE) > -1
            : false;
        setIsAdmin(isAdminValue);
        setCurrentUserData(currentUser?.data !== undefined ? currentUser?.data : null);
    }, [currentUser, currentUser.data]);

    return [isAdmin, currentUserData];
}
