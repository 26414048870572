import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import authService from '../../services/AuthService';

export default function App() {
  const navigate = useNavigate();

  useEffect(() => {
    authService.getUser()
      .then(user => {
        if (user === null) {
          authService.login({ state: { pathname: window.location.pathname } });
        } else {
          navigate('/users');
        }
      })
      .catch(() => {
        navigate('/access-deny');
      });
  }, []);

  return (
    <React.Fragment></React.Fragment>
  );
}
