import React, { useEffect, useState } from 'react';
import { useTheme, Box, Button, Grid, lighten } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { UserContactModel, ContactTypeLabels, ContactType } from '../../typings';
import { UserContactSchema } from '../../validationSchemes';
import { SelectInput, TextInput } from '@lp/lp-ui';
import { userDataSelected } from '../../app/userSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { NewUserContactModel } from '../../functions';
import { useCurrentUser } from '../../app/useCurrentUser';

type ContactPanelProps = {
    data: UserContactModel,
    handleClose: () => void
}

const ContactPanel: React.FC<ContactPanelProps> = ({ data, handleClose }: ContactPanelProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const userState = useAppSelector(store => store.user);
    const [isAdmin, _] = useCurrentUser();
    const [contactTypeSelected, setContactTypeSelected] = useState<ContactType>(data.contactType);

    useEffect(() => {
        setContactTypeSelected(data.contactType);
    }, [data]);

    const formik = useFormik<UserContactModel>({
        initialValues: data
            ? { ...data, phoneExtension: data.phoneExtension !== undefined ? data.phoneExtension : "" }
            : NewUserContactModel(),
        enableReinitialize: true,
        validationSchema: UserContactSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const {
        errors,
        touched,
        values,
        getFieldProps,
        submitForm,
        handleChange
    } = formik;

    const handleSubmit = (contact: UserContactModel) => {
        if (userState.data !== null) {
            const index = userState.data.contacts.indexOf(data);
            if (index !== undefined && index > -1) {
                const userContactsClone = Object.assign([], userState.data?.contacts);
                userContactsClone.splice(index, 1, contact);
                dispatch(userDataSelected({ ...userState.data, contacts: userContactsClone }));
            }
            else {
                const userContactsClone = Object.assign([], userState.data.contacts);
                userContactsClone.push(contact);
                dispatch(userDataSelected({ ...userState.data, contacts: userContactsClone }));
            }
        }
        formik.resetForm();
        handleClose();
    };

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={submitForm}>
                    <Box py={3} px={5} sx={{ background: lighten(theme.palette.primary.light, 0.5) }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={0} columnSpacing={0}>
                                    <Grid item xs={12}>
                                        <SelectInput
                                            label="Type"
                                            isEditing={isAdmin}
                                            required
                                            {...getFieldProps("contactType")}
                                            options={ContactTypeLabels}
                                            onChange={(e: React.ChangeEvent<any>) => { handleChange(e); setContactTypeSelected(e.target.value); }}
                                            error={Boolean(touched.contactType && errors.contactType)}
                                            helperText={touched.contactType && errors.contactType}
                                            value={ContactTypeLabels.find(ctt => ctt.value === values.contactType)?.value}
                                        />
                                    </Grid>
                                    <Grid item xs={contactTypeSelected === ContactType.Phone ? 8 : 12}>
                                        <TextInput
                                            isEditing={isAdmin}
                                            required
                                            type="string"
                                            label="Contact"
                                            {...getFieldProps("contact")}
                                            error={Boolean(touched.contact && errors.contact)}
                                            helperText={touched.contact && errors.contact}
                                        />
                                    </Grid>
                                    {contactTypeSelected === ContactType.Phone &&
                                        <React.Fragment>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={3}>
                                                < TextInput
                                                    isEditing={isAdmin}
                                                    type="string"
                                                    label="Extension"
                                                    {...getFieldProps("phoneExtension")}
                                                    error={Boolean(touched.phoneExtension && errors.phoneExtension)}
                                                    helperText={touched.phoneExtension && errors.phoneExtension}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex">
                                    <Box flexGrow={1} />
                                    <Button onClick={() => { formik.resetForm(); handleClose(); }}>Cancel</Button>
                                    <Button onClick={submitForm}>Ok</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}

export default ContactPanel;
