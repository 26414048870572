import React, { useEffect } from 'react'
import { Container, Box, ThemeProvider } from '@mui/material';
import {
    BrowserRouter as Router, Routes, Route, Outlet
} from "react-router-dom";
import App from './components/App';
import Users from './components/Users';
import User from './components/User';
import SigninOidc from './components/SigninOidc';
import SilentRenew from './components/SilentRenew';
import AccessDeny from './components/AccessDeny';
import { lightTheme, darkTheme } from '@lp/lp-ui'
import Header from './components/Header';
import Footer from './components/Footer';
import './index.css';
import authService from './services/AuthService';
import { currentUserUnloaded, getCurrentUser, getCurrentUserApplications } from './app/currentUserSlice';
import { useAppDispatch } from './app/hooks';

const Main: React.FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        authService.getUser()
            .then(user => {
                if (user !== null) {
                    dispatch(getCurrentUser());
                    dispatch(getCurrentUserApplications());
                }
            });

        const userLoaded = () => {
            dispatch(getCurrentUser());
            dispatch(getCurrentUserApplications());
        }
        const userUnloaded = () => {
            dispatch(currentUserUnloaded());
        };

        authService.userManager.events.addUserLoaded(userLoaded);
        authService.userManager.events.addUserUnloaded(userUnloaded);

        return () => {
            authService.userManager.events.removeUserLoaded(userLoaded);
            authService.userManager.events.removeUserUnloaded(userUnloaded);
        };
    }, []);

    return (
        <Router>
            <Routes>
                <Route element={<AdminLayout />}>
                    <Route path="/" element={<App />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/:id" element={<User />} />
                    <Route path="/signin-oidc" element={<SigninOidc />} />
                    <Route path="/access-deny" element={<AccessDeny />} />
                </Route>
                <Route path="/silent-renew" element={<SilentRenew />} />
            </Routes>
        </Router>
    )
}

const AdminLayout = () => (
    <ThemeProvider theme={lightTheme}>
        <Container component="div" maxWidth="lg" sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <Box>
                <Header sx={{ mb: 1.3, mt: 0.2 }} />
                <Box>
                    <Outlet />
                </Box>
            </Box>
            <Footer sx={{ m: 2 }} />
        </Container >
    </ThemeProvider >
);

export default Main;
