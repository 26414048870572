import React from "react";
import {
    useTheme, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, lighten
} from "@mui/material";
import { UserPasswordModel } from "../../typings";
import { CopyToClipboardButton } from "@lp/lp-ui";

interface PasswordDialogProps {
    userPwdData: UserPasswordModel | null,
    handleClose: () => void,
    handleEmailNewUser: () => void
}

const UserPasswordDialog: React.FC<PasswordDialogProps> = ({ userPwdData: userPasswordData, handleClose, handleEmailNewUser }: PasswordDialogProps) => {
    const theme = useTheme();

    return (
        <Dialog
            open={userPasswordData !== null}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title" variant="h3">
                Attention
            </DialogTitle>
            <DialogContent>
                <Box minWidth={300}>
                    <Typography marginBottom={1}>
                        The password for the user "{userPasswordData?.userName}" is:
                    </Typography>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        <Box component="span" pt={0.5} pb={0.5} pl={1} pr={1} border="solid 1px" borderRadius={1} bgcolor={lighten(theme.palette.primary.light, 0.5)}>
                            {userPasswordData?.password}
                        </Box>
                        <CopyToClipboardButton text={userPasswordData?.password ?? ""} size='small' />
                    </Box>
                    <Typography marginTop={1}>
                        This is the last time this password will be shown.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleEmailNewUser} color="primary" variant="contained">Email User</Button>
                <Button onClick={handleClose} color="primary" variant="contained">Done</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserPasswordDialog;
