import React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { UserNameSchema } from '../../validationSchemes';
import { EntityEditDialog, TextInput } from '@lp/lp-ui';
import { updateUserName, UpdateUserNameRequest } from '../../app/userSlice'
import { useAppDispatch } from '../../app/hooks';
import { useCurrentUser } from '../../app/useCurrentUser';

interface UserNameModel {
    userName: string
}

type UserNameEditDialogProps = {
    open: boolean,
    userId: number,
    userName: string,
    handleClose: () => void
}

const UserNameEditDialog: React.FC<UserNameEditDialogProps> = (props: UserNameEditDialogProps) => {
    const dispatch = useAppDispatch();
    const [isAdmin, _] = useCurrentUser();

    const formik = useFormik<UserNameModel>({
        initialValues: { userName: props.userName },
        enableReinitialize: true,
        validationSchema: UserNameSchema,
        onSubmit: async (values) => {
            const request: UpdateUserNameRequest = {
                userId: props.userId,
                userName: values.userName
            };
            const result = await dispatch(updateUserName(request));
            if (result.meta.requestStatus === 'fulfilled') {
                handlePopupClose();
                return true;
            }
            return false;
        }
    });

    const {
        errors,
        touched,
        getFieldProps,
        submitForm
    } = formik;

    const handlePopupClose = () => {
        formik.resetForm();
        props.handleClose();
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={submitForm}>
                    <EntityEditDialog
                        open={props.open}
                        editing={isAdmin}
                        maxWidth="sm"
                        fullWidth
                        title="User Name"
                        handleClose={handlePopupClose}
                        handleSave={submitForm}
                        showMoreMenu={false}
                    >
                        <TextInput
                            isEditing={isAdmin}
                            required
                            type="string"
                            label="User Name"
                            {...getFieldProps("userName")}
                            error={Boolean(touched.userName && errors.userName)}
                            helperText={touched.userName && errors.userName}
                        />
                    </EntityEditDialog>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}

export default UserNameEditDialog;
