import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    TableHead, TableRow, TableCell, TableBody, TableFooter,
    TablePagination, TableSortLabel, Paper, TextField,
    InputAdornment, IconButton, Grid, Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useDebounce from '../../app/useDebounce';
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Direction, UserFields, UserListItemModel } from '../../typings'
import { usersFilter, paginationSelected } from '../../app/usersSlice'
import { SkeletonTableRows, StyledTable, TitleBar } from '@lp/lp-ui';
import AddUserDialog from './AddUserDialog';
import { userDataReset } from '../../app/userSlice';
import { useCurrentUser } from '../../app/useCurrentUser';

export default function Users() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { loading, data, pagination } = useAppSelector(store => store.users);
    const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
    const [filterString, setFilterString] = useState(pagination.filterString);
    const debouncedFilterString = useDebounce<string>(filterString);
    const [isAdmin, _] = useCurrentUser();

    useEffect(() => {
        if (pagination.filterString !== debouncedFilterString) {
            dispatch(
                paginationSelected({ ...pagination, filterString: debouncedFilterString, page: { pageNumber: 0, pageSize: pagination.page.pageSize } })
            );
        }
    }, [debouncedFilterString])

    const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        dispatch(
            paginationSelected({ ...pagination, page: { pageNumber: newPage, pageSize: pagination.page.pageSize } })
        );
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(
            paginationSelected({ ...pagination, page: { pageNumber: 0, pageSize: (parseInt(event.target.value, 10)) } })
        );
    };

    useEffect(() => {
        dispatch(usersFilter(pagination));
    }, [pagination]);

    return (
        <React.Fragment>
            <TitleBar
                title='Users'
                subtitle={`${data.totalCount} records found`}
                actionComponents={[
                    <React.Fragment key="sl-1">
                        {isAdmin &&
                            <Button key="ac-1" variant="outlined" onClick={() => {
                                dispatch(userDataReset());
                                setNewUserDialogOpen(true);
                            }}>
                                New User
                            </Button>
                        }
                    </React.Fragment>
                ]}
            />
            <Paper>
                <Grid container spacing={1} m={1}>
                    <Grid item xs={4}>
                        <TextField
                            placeholder='Quick Search'
                            variant='outlined'
                            size='small'
                            value={filterString}
                            fullWidth
                            autoComplete='false'
                            onChange={(e) => setFilterString(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <StyledTable stickyHeader>
                    <TableHead>
                        <TableRow>
                            {
                                [
                                    { fieldDef: UserFields[1], align: 'left' },
                                    { fieldDef: UserFields[2], align: 'left' },
                                    { fieldDef: UserFields[3], align: 'left' },
                                    { fieldDef: UserFields[4], align: 'left' },
                                    { fieldDef: UserFields[5], align: 'left' }
                                ].map((obj) => (
                                    <TableCell
                                        key={obj.fieldDef.fieldName}
                                        align={obj.align as "left" | "center" | "right" | "justify" | "inherit" | undefined}
                                    >
                                        <TableSortLabel
                                            active={pagination.sort.sortBy === (obj.fieldDef.fieldName)}
                                            direction={pagination.sort.sortDirection === Direction.Ascending
                                                ? "asc"
                                                : "desc"}
                                            onClick={() => {
                                                dispatch(paginationSelected({
                                                    ...pagination,
                                                    sort: {
                                                        sortBy: obj.fieldDef.fieldName as keyof UserListItemModel,
                                                        sortDirection: pagination.sort.sortBy === obj.fieldDef.fieldName
                                                            ? (
                                                                pagination.sort.sortDirection === Direction.Ascending ? Direction.Descending : Direction.Ascending
                                                            )
                                                            : pagination.sort.sortDirection
                                                    }
                                                }));
                                            }}
                                        >
                                            {obj.fieldDef.displayName}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            <TableCell key="enabled" align='center'>Enabled</TableCell>
                            <TableCell key="next"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading
                            ?
                            <SkeletonTableRows rows={pagination.page.pageSize} columns={7} />
                            :
                            data.items.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.userName}</TableCell>
                                    <TableCell>{row.firstName}</TableCell>
                                    <TableCell>{row.lastName}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.phoneNumber}</TableCell>
                                    <TableCell align='center'>{row.enabled && <CheckIcon />}</TableCell>
                                    <TableCell align="right">
                                        <IconButton size="small" onClick={() => { navigate(`/users/${row.id}`) }}>
                                            <NavigateNextIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 15, 20]}
                                colSpan={7}
                                count={data.totalCount}
                                rowsPerPage={pagination.page.pageSize}
                                page={pagination.page.pageNumber}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableBody>
                </StyledTable>
            </Paper>

            <AddUserDialog
                open={newUserDialogOpen}
                handleClose={() => { setNewUserDialogOpen(false); }}
            />
        </React.Fragment>
    );
}
