import {
    createSlice, createAsyncThunk
} from '@reduxjs/toolkit';
import {
    ApiViewEntityResponse, ApiViewResponseError, ApplicationModel, CurrentUserModel
} from '../typings'
import { getBodyErrors } from '../services/ApiService';
import identityService from '../services/IdentityService';

interface CurrentUserState {
    data: CurrentUserModel | undefined | null,
    applications: ApplicationModel[] | undefined,
    pending: boolean
}

const initialState: CurrentUserState = {
    data: undefined,
    applications: undefined,
    pending: false
}

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        currentUserUnloaded: (state) => {
            state.data = undefined;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getCurrentUser.fulfilled, (state, action) => {
                state.data = action.payload.data;
                state.pending = false;
            })
            .addCase(getCurrentUser.pending, (state) => {
                state.pending = true;
            })
            .addCase(getCurrentUser.rejected, (state) => {
                state.data = null;
                state.pending = false;
            })
            .addCase(getCurrentUserApplications.fulfilled, (state, action) => {
                state.applications = action.payload.data;
                state.pending = false;
            })
            .addCase(getCurrentUserApplications.pending, (state) => {
                state.pending = true;
            })
            .addCase(getCurrentUserApplications.rejected, (state) => {
                state.data = null;
                state.pending = false;
            })
    }
});

export const getCurrentUser = createAsyncThunk<ApiViewEntityResponse<CurrentUserModel>, undefined, { rejectValue: ApiViewResponseError[] }>
    ('user/current/get', async (_, { rejectWithValue }) => {
        try {
            const response = await identityService.getCurrentUser();
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const getCurrentUserApplications = createAsyncThunk<ApiViewEntityResponse<ApplicationModel[]>, undefined, { rejectValue: ApiViewResponseError[] }>
    ('user/current/applications/get', async (_, { rejectWithValue }) => {
        try {
            const response = await identityService.getCurrentUserApplications();
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const { currentUserUnloaded } = currentUserSlice.actions;
export default currentUserSlice.reducer
