import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import authService from '../../services/AuthService';
import { LoadingBackDrop } from '@lp/lp-ui';
import { useAppDispatch } from '../../app/hooks';
import { getCurrentUser, getCurrentUserApplications } from '../../app/currentUserSlice';
import { OidcState } from '../../typings';

export default function SigninOidc() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        authService.signinRedirectCallback()
            .then(function (user) {
                dispatch(getCurrentUser());
                dispatch(getCurrentUserApplications());
                const oidcState = user.state as OidcState;
                navigate(oidcState.pathname);
            })
            .catch(function (e) {
                const error: string = e as string;
                if (error == "ErrorResponse: Forbidden" || error == "ErrorResponse: NotFound") {
                    navigate('/access-deny');
                }
            });
    }, []);

    return (
        <React.Fragment>
            <LoadingBackDrop open={true} />
        </React.Fragment>
    );
}
