import {
    createSlice, createAsyncThunk, isRejectedWithValue, isPending, isFulfilled, PayloadAction
} from '@reduxjs/toolkit';
import {
    ApiViewEntityResponse, ApiViewResponseError,
    UserDataModel, UserModel, UserContactDataModel, UserAddressModel,
    UserProfileDataModel, ApiViewResponse, UserValidationDataModel,
    UserProfileResponseModel, UserClientModel, UserPasswordModel,
    PaginationResponse, AuditLog, AuditLogDetails, UserPersonalDetailsModel
} from '../typings'
import { getBodyErrors } from '../services/ApiService';
import identityService from '../services/IdentityService';
import { MapUserModel } from '../mappings';
import { Message, MessageLevel } from '@lp/lp-ui';

interface UserState {
    loading: boolean,
    loadingAuditLogs: boolean,
    snack: string | null,
    id: number,
    data: UserDataModel | null,
    auditLogs: PaginationResponse<AuditLog>,
    messages: Message[]
}

const initialState: UserState = {
    loading: false,
    loadingAuditLogs: false,
    snack: null,
    id: 0,
    data: null,
    auditLogs: {
        pageNumber: 0,
        totalCount: 0,
        items: []
    },
    messages: []
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        idSelected: (state, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
        userDataSelected: (state, action: PayloadAction<UserDataModel>) => {
            state.data = action.payload;
        },
        userDataReset: (state) => {
            state.data = null;
        },
        snackDisplayed: (state, action: PayloadAction<string | null>) => {
            state.snack = action.payload;
        },
        messagesDisplayed: (state, action: PayloadAction<Message[]>) => {
            state.messages = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(validateUserProfile.fulfilled, (state) => {
                state.loading = false;
                state.messages = [];
                state.snack = null;
            })
            .addCase(createUserApplicationRelation.fulfilled, (state, action) => {
                state.loading = false;
                state.messages = [];
                state.snack = null;
                if (state.data !== null) {
                    const request = action.meta.arg;
                    const userClient: UserClientModel = {
                        clientId: request.clientId
                    };
                    const userClientsClone = Object.assign([], state.data.userClients);
                    userClientsClone.push(userClient);
                    state.data = { ...state.data, userClients: userClientsClone };
                }
            })
            .addCase(deleteUserApplicationRelation.fulfilled, (state, action) => {
                state.loading = false;
                state.messages = [];
                state.snack = null;
                if (state.data !== null) {
                    const request = action.meta.arg;
                    const index = state.data.userClients.findIndex(c => c.clientId === request.clientId);
                    if (index !== undefined && index > -1) {
                        const userClientsClone = Object.assign([], state.data.userClients);
                        userClientsClone.splice(index, 1);
                        state.data = { ...state.data, userClients: userClientsClone };
                    }
                }
            })
            .addCase(emailUserPassword.fulfilled, (state) => {
                state.loading = false;
                state.messages = [{ details: "Email has been successfully sent.", level: MessageLevel.Information }];
                state.snack = null;
            })
            .addCase(emailNewUser.fulfilled, (state) => {
                state.loading = false;
                state.messages = [];
                state.snack = null;
            })
            .addCase(getAuditLogs.pending, (state) => {
                state.loadingAuditLogs = true;
            })
            .addCase(getAuditLogs.rejected, (state, action) => {
                state.loadingAuditLogs = false;
                state.snack = null;
                if (action.payload) {
                    state.messages = action.payload.map(e => ({ details: e.detail, level: MessageLevel.Error }));
                }
            })
            .addCase(getAuditLogs.fulfilled, (state, action) => {
                state.loadingAuditLogs = false;
                state.snack = null;
                state.auditLogs = action.payload.data;
            })
            .addCase(getAuditLogDetails.fulfilled, (state) => {
                state.loading = false;
                state.snack = null;
            })
            .addMatcher(isApiFulfilledWithoutSnack, (state, action) => {
                state.loading = false;
                state.messages = [];
                state.snack = null;
                state.data = MapUserModel(action.payload.data);
            })
            .addMatcher(isApiFulfilledWithSnackMessage, (state, action) => {
                state.loading = false;
                state.messages = [];
                state.snack = "Record Saved.";
                state.data = MapUserModel(action.payload.data);
            })
            .addMatcher(isApiPending, (state) => {
                state.loading = true;
            })
            .addMatcher(isApiRejected, (state, action) => {
                state.loading = false;
                state.snack = null;
                if (action.payload) {
                    state.messages = action.payload.map(e => ({ details: e.detail, level: MessageLevel.Error }));
                }
            })
    }
});

export const getUser = createAsyncThunk<ApiViewEntityResponse<UserModel>, number, { rejectValue: ApiViewResponseError[] }>
    ('user/get', async (id: number, { rejectWithValue }) => {
        try {
            const response = await identityService.getUser(id);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const validateUserProfile = createAsyncThunk<ApiViewResponse, UserValidationDataModel, { rejectValue: ApiViewResponseError[] }>
    ('user/profile/validate', async (data: UserValidationDataModel, { rejectWithValue }) => {
        try {
            const response = await identityService.validateUserProfile(data);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const createUserProfile = createAsyncThunk<ApiViewEntityResponse<UserProfileResponseModel>, UserDataModel, { rejectValue: ApiViewResponseError[] }>
    ('user/profile/create', async (profile: UserDataModel, { rejectWithValue }) => {
        try {
            const response = await identityService.createUserProfile(profile);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateUserProfile = createAsyncThunk<ApiViewEntityResponse<UserModel>, UpdateUserProfileRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/profile/update', async (request: UpdateUserProfileRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.updateUserProfile(request.userId, request.profile);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateUserPersonalDetails = createAsyncThunk<ApiViewEntityResponse<UserModel>, UpdateUserPersonalDetailsRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/personal-details/update', async (request: UpdateUserPersonalDetailsRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.updateUserPersonalDetails(request.userId, request.personalDetails);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const createUserAddress = createAsyncThunk<ApiViewEntityResponse<UserModel>, CreateUserAddressRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/address/create', async (request: CreateUserAddressRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.createUserAddress(request.userId, request.userAddress);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateUserAddress = createAsyncThunk<ApiViewEntityResponse<UserModel>, UpdateUserAddressRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/address/update', async (request: UpdateUserAddressRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.updateUserAddress(request.userId, request.userAddressId, request.userAddress);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const createUserContact = createAsyncThunk<ApiViewEntityResponse<UserModel>, CreateUserContactRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/contact/create', async (request: CreateUserContactRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.createUserContact(request.userId, request.contact);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateUserContact = createAsyncThunk<ApiViewEntityResponse<UserModel>, UpdateUserContactRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/contact/update', async (request: UpdateUserContactRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.updateUserContact(request.userId, request.contactId, request.contact);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const deleteUserContact = createAsyncThunk<ApiViewEntityResponse<UserModel>, DeleteUserContactRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/contact/delete', async (request: DeleteUserContactRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.deleteUserContact(request.userId, request.contactId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const createUserApplicationRelation = createAsyncThunk<ApiViewResponse, UserClientRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/application/create', async (request: UserClientRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.createUserApplicationRelation(request.userId, request.clientId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const deleteUserApplicationRelation = createAsyncThunk<ApiViewResponse, UserClientRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/application/delete', async (request: UserClientRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.deleteUserApplicationRelation(request.userId, request.clientId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const enableUser = createAsyncThunk<ApiViewEntityResponse<UserModel>, number, { rejectValue: ApiViewResponseError[] }>
    ('user/enable', async (userId: number, { rejectWithValue }) => {
        try {
            const response = await identityService.enableUser(userId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const disableUser = createAsyncThunk<ApiViewEntityResponse<UserModel>, number, { rejectValue: ApiViewResponseError[] }>
    ('user/disable', async (userId: number, { rejectWithValue }) => {
        try {
            const response = await identityService.disableUser(userId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const enableMfa = createAsyncThunk<ApiViewEntityResponse<UserModel>, number, { rejectValue: ApiViewResponseError[] }>
    ('user/mfa/enable', async (userId: number, { rejectWithValue }) => {
        try {
            const response = await identityService.enableMfa(userId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const disableMfa = createAsyncThunk<ApiViewEntityResponse<UserModel>, number, { rejectValue: ApiViewResponseError[] }>
    ('user/mfa/disable', async (userId: number, { rejectWithValue }) => {
        try {
            const response = await identityService.disableMfa(userId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const forcePasswordReset = createAsyncThunk<ApiViewEntityResponse<UserProfileResponseModel>, number, { rejectValue: ApiViewResponseError[] }>
    ('user/forcePasswordReset', async (userId: number, { rejectWithValue }) => {
        try {
            const response = await identityService.forcePasswordReset(userId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const emailUserPassword = createAsyncThunk<ApiViewEntityResponse<UserModel>, UserPasswordModel, { rejectValue: ApiViewResponseError[] }>
    ('user/emailUserPassword', async (request: UserPasswordModel, { rejectWithValue }) => {
        try {
            const response = await identityService.emailUserPassword(request.id, request.password);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const emailNewUser = createAsyncThunk<ApiViewEntityResponse<UserModel>, UserPasswordModel, { rejectValue: ApiViewResponseError[] }>
    ('user/emailNewUser', async (request: UserPasswordModel, { rejectWithValue }) => {
        try {
            const response = await identityService.emailNewUser(request.id, request.password);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateUserName = createAsyncThunk<ApiViewEntityResponse<UserModel>, UpdateUserNameRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/update/userName', async (request: UpdateUserNameRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.updateUserName(request.userId, request.userName);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateEmail = createAsyncThunk<ApiViewEntityResponse<UserModel>, UpdateEmailRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/update/email', async (request: UpdateEmailRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.updateEmail(request.userId, request.email);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const getAuditLogs = createAsyncThunk<ApiViewEntityResponse<PaginationResponse<AuditLog>>, number, { rejectValue: ApiViewResponseError[] }>
    ('user/auditLogs/get', async (userId: number, { rejectWithValue }) => {
        try {
            const response = await identityService.getAuditLogs(userId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const getAuditLogDetails = createAsyncThunk<ApiViewEntityResponse<AuditLogDetails>, GetAuditLogDetailsRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/auditLogs/details/get', async (request: GetAuditLogDetailsRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.getAuditLogDetails(request.userId, request.auditLogId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

const isApiPending = isPending(
    getUser, validateUserProfile, createUserProfile, updateUserProfile, updateUserPersonalDetails,
    createUserAddress, updateUserAddress, createUserContact, updateUserContact, deleteUserContact,
    createUserApplicationRelation, deleteUserApplicationRelation, enableUser, disableUser, enableMfa, disableMfa,
    forcePasswordReset, emailUserPassword, emailNewUser, updateUserName, updateEmail, getAuditLogDetails);
const isApiRejected = isRejectedWithValue(
    getUser, validateUserProfile, createUserProfile, updateUserProfile, updateUserPersonalDetails,
    createUserAddress, updateUserAddress, createUserContact, updateUserContact, deleteUserContact,
    createUserApplicationRelation, deleteUserApplicationRelation, enableUser, disableUser, enableMfa, disableMfa,
    forcePasswordReset, emailUserPassword, emailNewUser, updateUserName, updateEmail, getAuditLogs,
    getAuditLogDetails);
const isApiFulfilledWithSnackMessage = isFulfilled(
    updateUserProfile, updateUserPersonalDetails, createUserAddress,
    updateUserAddress, createUserContact, updateUserContact,
    deleteUserContact);
const isApiFulfilledWithoutSnack = isFulfilled(
    getUser, createUserProfile, enableUser, disableUser, enableMfa, disableMfa,
     forcePasswordReset, updateUserName, updateEmail);

export default userSlice.reducer

export const {
    idSelected,
    userDataSelected,
    userDataReset,
    snackDisplayed,
    messagesDisplayed
} = userSlice.actions;

export interface UpdateUserProfileRequest {
    userId: number,
    profile: UserProfileDataModel
}

export interface UpdateUserPersonalDetailsRequest {
    userId: number,
    personalDetails: UserPersonalDetailsModel
}

export interface CreateUserAddressRequest {
    userId: number,
    userAddress: UserAddressModel
}

export interface UpdateUserAddressRequest extends CreateUserAddressRequest {
    userAddressId: number
}

export interface CreateUserContactRequest {
    userId: number,
    contact: UserContactDataModel
}

export interface UpdateUserContactRequest extends CreateUserContactRequest {
    contactId: number
}

export interface DeleteUserContactRequest {
    userId: number,
    contactId: number
}

export interface UserClientRequest {
    userId: number,
    clientId: number
}

export interface UpdateUserNameRequest {
    userId: number,
    userName: string
}

export interface UpdateEmailRequest {
    userId: number,
    email: string
}

export interface GetAuditLogDetailsRequest {
    userId: number,
    auditLogId: number
}
