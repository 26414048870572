import authService from './AuthService';
import { ApiViewResponseError, ApiViewResponse } from '../typings';

class ApiService {
  public async fetch(url: RequestInfo, opts: RequestInit, retry: boolean = false): Promise<Response> {
    return new Promise<Response>(async (resolve, reject) => {
      const user = await authService.getUser();
      const res = await fetch(url, {
        ...opts,
        headers: {
          'Content-Type': "application/json",
          'Authorization': `bearer ${user?.access_token}`
        }
      });
      if (res.status === 401 && !retry) {
        authService.renewToken()
          .then(() => {
            this.fetch(url, opts, true)
              .then(r => resolve(r))
              .catch(e => reject(e));
          })
          .catch(async () => {
            await authService.removeUser();
            await authService.login({ state: { pathname: window.location.pathname } });
          });
      }
      else {
        if (res.ok) {
          resolve(res);
        }
        else {
          reject(res);
        }
      }
    })
  }
}

export const getBodyErrors = async (err: any): Promise<ApiViewResponseError[]> => {
  if (err.body) {
    const apiResponseBody = await err.json() as ApiViewResponse;
    return (apiResponseBody.errors);
  }
  return [{ detail: err.message ?? err.toString(), title: 'Error', code: '', id: '' }];
}

const apiService = new ApiService();
export default apiService;
