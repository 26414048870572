import React, { ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import { Form, FormikProvider, FormikProps } from 'formik';
import { UserProfileDataModel } from '../../typings';
import { CheckInput, PhoneNumberInput, TextInput } from '@lp/lp-ui';
import { useCurrentUser } from '../../app/useCurrentUser';

interface BasicInfoPanelProps {
    formik: FormikProps<UserProfileDataModel>
}

const NewUserBasicInfoPanel: React.FC<BasicInfoPanelProps> = ({ formik }) => {
    const [isAdmin, _] = useCurrentUser();

    const {
        errors,
        touched,
        getFieldProps,
        submitForm
    } = formik;

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={submitForm}>
                    <Grid container spacing={1} columnSpacing={6}>
                        <Grid item xs={6}>
                            <TextInput
                                isEditing={isAdmin}
                                type="string"
                                label="User Name"
                                required
                                {...getFieldProps("userName")}
                                error={Boolean(touched.userName && errors.userName)}
                                helperText={touched.userName && errors.userName} />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <TextInput
                                isEditing={isAdmin}
                                type="string"
                                label="First Name"
                                required
                                {...getFieldProps("firstName")}
                                error={Boolean(touched.firstName && errors.firstName)}
                                helperText={touched.firstName && errors.firstName} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                isEditing={isAdmin}
                                type="string"
                                label="Last Name"
                                required
                                {...getFieldProps("lastName")}
                                error={Boolean(touched.lastName && errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                isEditing={isAdmin}
                                type="string"
                                label="Email"
                                {...getFieldProps("email")}
                                required
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email} />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <PhoneNumberInput
                                isEditing={isAdmin}
                                defaultCountry="US"
                                label="Phone Number"
                                {...getFieldProps("phoneNumber")}
                                onChange={(e: string | ChangeEvent<any>) => { formik.setFieldValue("phoneNumber", e); }}
                                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                helperText={touched.phoneNumber && errors.phoneNumber}
                            />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={12}>
                            <CheckInput
                                label="Enabled"
                                isEditing={isAdmin}
                                {...getFieldProps("enabled")}
                                error={Boolean(touched.enabled && errors.enabled)}
                                helperText={touched.enabled && errors.enabled}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CheckInput
                                label="MFA"
                                isEditing={isAdmin}
                                {...getFieldProps("twoFactorEnabled")}
                                error={Boolean(touched.twoFactorEnabled && errors.twoFactorEnabled)}
                                helperText={touched.twoFactorEnabled && errors.twoFactorEnabled}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}

export default NewUserBasicInfoPanel;
