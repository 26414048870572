import React from 'react';
import { useTheme, Box, Typography, Link, lighten } from '@mui/material';
import { REACT_APP_LOGIN_URL } from '../../constants'

export default function AccessDeny() {
    const theme = useTheme();

    return (
        <React.Fragment>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{ mt: 20 }}>
                <Box></Box>
                <Box textAlign="center">
                    <Box textAlign="center" border="solid" minWidth="250px" minHeight="100px"
                        display="flex" flexDirection="column" justifyContent="center"
                        sx={{ backgroundColor: lighten(theme.palette.primary.light, 0.5) }}>
                        <Typography color="error" variant="h4" padding="5">
                            Access Denied
                        </Typography>
                    </Box>
                    <Typography variant='body2' paddingTop={0.5}>
                        <Link color="inherit" href={REACT_APP_LOGIN_URL}>Go to Sign In</Link>
                    </Typography>
                </Box>
                <Box></Box>
            </Box>
        </React.Fragment>
    );
}
