import React from "react";
import {
    useTheme, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, lighten
} from "@mui/material";
import { UserPasswordModel } from "../../typings";
import { CopyToClipboardButton } from "@lp/lp-ui";

interface PasswordDialogProps {
    userPwdData: UserPasswordModel | null,
    handleClose: () => void,
    handleEmailPassword: () => void
}

const UserPasswordDialog: React.FC<PasswordDialogProps> = ({ userPwdData: userPasswordData, handleClose, handleEmailPassword }: PasswordDialogProps) => {
    const theme = useTheme();

    return (
        <Dialog
            open={userPasswordData !== null}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title" variant="h3">
                Reset password successful for {userPasswordData?.firstName} {userPasswordData?.lastName}
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography marginBottom={2}>
                        This is the last time this password will be shown. Provide this temporary password to the user. The user will be forced to reset the password after the next login.
                    </Typography>
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                        <Box component="span" pt={0.5} pb={0.5} pl={1} pr={1} border="solid 1px" borderRadius={1} bgcolor={lighten(theme.palette.primary.light, 0.5)}>
                            {userPasswordData?.password}
                        </Box>
                        <CopyToClipboardButton text={userPasswordData?.password ?? ""} size='small' />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleEmailPassword} color="primary" variant="contained">Email Password</Button>
                <Button onClick={handleClose} color="primary" variant="contained">Done</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserPasswordDialog;
