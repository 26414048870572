import { CacheLookup } from "./app/cacheSlice";
import { UserModel, UserDataModel, CountryModel, ApplicationModel } from "./typings";

export function MapUserModel(user: UserModel): UserDataModel {
    const result: UserDataModel = {
        userName: user.userName,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        enabled: user.enabled,
        twoFactorEnabled: user.twoFactorEnabled,
        forcePasswordReset: user.forcePasswordReset,
        contacts: user.contacts,
        userAddresses: user.userAddresses,
        userClients: user.userClients
    };

    return result;
}

export function MapCountryModel(country: CountryModel): CacheLookup {
    const result: CacheLookup = {
        id: 0,
        key: country.countryAlpha3Code,
        label: country.name
    };

    return result;
}

export function MapApplicationModel(application: ApplicationModel): CacheLookup {
    const result: CacheLookup = {
        id: application.id,
        key: application.clientId,
        label: application.clientName
    };

    return result;
}
