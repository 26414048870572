import React from 'react';
import { Grid, Typography, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useCache from '../../app/useCache';
import { CachedEntity } from '../../app/cacheSlice';
import { createUserApplicationRelation, deleteUserApplicationRelation, UserClientRequest } from '../../app/userSlice';
import { useCurrentUser } from '../../app/useCurrentUser';

export default function UserApplicationsPanel() {
    const dispatch = useAppDispatch();
    const userState = useAppSelector(store => store.user);
    const applications = useCache(CachedEntity.Application);
    const [isAdmin, _] = useCurrentUser();

    const handleUserApplicationChange = (clientId: number, enabled: boolean) => {
        const request: UserClientRequest = {
            userId: userState.id,
            clientId: clientId
        };
        if (enabled) {
            dispatch(createUserApplicationRelation(request));
        }
        else {
            dispatch(deleteUserApplicationRelation(request));
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                        Applications
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        {applications.map((application) => (
                            <FormControlLabel
                                control={<Switch
                                    checked={userState?.data?.userClients.some(uc => uc.clientId === application.id)}
                                    onChange={(_, checked) => handleUserApplicationChange(application.id, checked) }
                                    disabled={!isAdmin || !userState?.data?.enabled}
                                />}
                                label={application.label ? application.label : application.key}
                                key={application.key}
                            />
                        ))}
                    </FormGroup>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
