import {
    Log, SigninRedirectArgs, SigninSilentArgs, SignoutRedirectArgs,
    User, UserManager, UserManagerSettings, WebStorageStateStore
} from 'oidc-client-ts';
import {
    REACT_APP_STS_AUTHORITY, REACT_APP_CLIENT_ID, REACT_APP_RESPONSE_TYPE, REACT_APP_CLIENT_SCOPE
} from '../constants'

class AuthService {
    public userManager: UserManager;
    constructor() {
        const settings: UserManagerSettings = {
            authority: REACT_APP_STS_AUTHORITY,
            client_id: REACT_APP_CLIENT_ID,
            redirect_uri: window.location.origin + '/signin-oidc',
            silent_redirect_uri: window.location.origin + '/silent-renew',
            post_logout_redirect_uri: window.location.origin,
            scope: REACT_APP_CLIENT_SCOPE,
            response_type: REACT_APP_RESPONSE_TYPE,
            response_mode: 'query',
            monitorSession: false,
            automaticSilentRenew: false,
            loadUserInfo: true,
            stopCheckSessionOnError: true,
            revokeTokensOnSignout: true,
            userStore: new WebStorageStateStore({ store: window.sessionStorage })
        };

        this.userManager = new UserManager(settings);

        Log.setLogger(console);
        Log.setLevel(Log.WARN);
    }

    public async getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public async login(args?: SigninRedirectArgs): Promise<void> {
        return this.userManager.signinRedirect(args);
    }

    public async renewToken(args?: SigninSilentArgs): Promise<User | null> {
        return this.userManager.signinSilent(args);
    }

    public async removeUser(): Promise<void> {
        return this.userManager.removeUser();
    }

    public async logout(args?: SignoutRedirectArgs): Promise<void> {
        return this.userManager.signoutRedirect(args);
    }

    public async signinRedirectCallback(url?: string): Promise<User> {
        return this.userManager.signinRedirectCallback(url);
    }

    public async signinSilentCallback(url?: string): Promise<void> {
        return this.userManager.signinSilentCallback(url);
    }
}

const authService = new AuthService();
export default authService;
