import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Step, StepLabel, Stepper, Box } from "@mui/material";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CloseConfirmationDialog, LoadingBackDrop, MessageDialog, StepperDialog } from "@lp/lp-ui";
import NewUserBasicInfoPanel from "./NewUserBasicInfoPanel";
import NewUserAddressPanel from "./NewUserAddressPanel";
import NewUserContactsPanel from "./NewUserContactsPanel";
import {
    AddressModel, ApiViewEntityResponse, UserDataModel, UserValidationDataModel,
    UserPasswordModel, UserProfileDataModel, UserProfileResponseModel
} from "../../typings";
import { AddressSchema, UserProfileDataSchema } from "../../validationSchemes";
import { NewAddressModel, NewUserAddressModel, NewUserProfileDataModel } from "../../functions";
import {
    createUserProfile, messagesDisplayed, userDataSelected, userDataReset, validateUserProfile, emailNewUser
} from "../../app/userSlice";
import UserPasswordDialog from "./UserPasswordDialog";
import NewUserApplicationsPanel from "./NewUserApplicationsPanel";

interface AddUserDialogProps {
    open: boolean,
    handleClose: () => void
}

const steps = ['Basic Details', 'Address', 'Contacts', 'Applications'];
const AddUserDialog: React.FC<AddUserDialogProps> = ({ open, handleClose }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { data, messages, loading } = useAppSelector(s => s.user);
    const [closeDialogOpen, setCloseDialogOpen] = useState(false);
    const [hasAddress, setHasAddress] = useState(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [userPasswordData, setUserPasswordData] = useState<UserPasswordModel | null>(null);

    const formikBasic = useFormik<UserProfileDataModel>({
        initialValues: data ?? NewUserProfileDataModel(),
        enableReinitialize: true,
        validationSchema: UserProfileDataSchema,
        onSubmit: async (values) => {
            const request: UserValidationDataModel = {
                userName: values.userName,
                email: values.email,
                phoneNumber: values.phoneNumber
            };
            const result = await dispatch(validateUserProfile(request));
            if (result.meta.requestStatus === 'fulfilled') {
                const userData: UserDataModel = {
                    userName: values.userName,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    enabled: values.enabled,
                    twoFactorEnabled: values.twoFactorEnabled,
                    forcePasswordReset: true,
                    userAddresses: data !== null ? data.userAddresses : [NewUserAddressModel()],
                    contacts: data !== null ? data.contacts : [],
                    userClients: data !== null ? data.userClients : []
                };
                dispatch(userDataSelected(userData));
                return true;
            }
            return false;
        }
    });

    const formikAddress = useFormik<AddressModel>({
        initialValues: data !== null && data.userAddresses.length > 0 ? data.userAddresses[0].address : NewAddressModel(),
        enableReinitialize: true,
        validationSchema: AddressSchema,
        onSubmit: async (values) => {
            handleAddressData(values);
            return true;
        }
    });

    const handleAddressData = (values: AddressModel) => {
        if (data !== null) {
            const userAddress = data?.userAddresses[0] ?? NewUserAddressModel();
            const userData: UserDataModel = {
                ...data, userAddresses: [{ ...userAddress, address: values }]
            };
            dispatch(userDataSelected(userData));
        }
    };

    const handleNext = async () => {
        if (activeStep === 0) {
            const result = await formikBasic.submitForm();
            if (result) {
                setActiveStep(1);
            }
        }
        else if (activeStep === 1) {
            if (hasAddress) {
                const result = await formikAddress.submitForm();
                if (result) {
                    setActiveStep(2);
                }
            } else {
                handleAddressData(formikAddress.values);
                setActiveStep(2);
            }
        }
        else {
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    // user clicked close, show confirmation
    const handleCloseClicked = () => {
        if (data !== null || formikBasic.dirty) {
            setCloseDialogOpen(true);
        } else {
            handleCloseOk();
        }
    };

    //actually close this time
    const handleCloseOk = async () => {
        formikBasic.resetForm();
        formikAddress.resetForm();
        setCloseDialogOpen(false);
        setActiveStep(0);
        dispatch(userDataReset());
        handleClose();
    };

    const handleSave = async () => {
        if (data !== null) {
            const userData: UserDataModel = !hasAddress
                ? { ...data, userAddresses: [] }
                : data;
            const result = await dispatch(createUserProfile(userData));
            if (result.meta.requestStatus === 'fulfilled') {
                const userData = result.payload as ApiViewEntityResponse<UserProfileResponseModel>;
                setUserPasswordData(userData.data);
            }
        }
    };

    const handlePasswordDialogClose = () => {
        const userId = userPasswordData?.id;
        dispatch(userDataReset());
        setUserPasswordData(null);
        setActiveStep(0);
        handleClose();
        navigate(`/users/${userId}`);
    };

    const handlePasswordDialogEmailNewUser = async () => {
        if (userPasswordData) {
            dispatch(emailNewUser(userPasswordData));
        }
        handlePasswordDialogClose();
    }

    const handleAddress = (hasAddress: boolean) => {
        setHasAddress(hasAddress);
    };

    return (
        <React.Fragment>
            <StepperDialog
                handleCancel={handleCloseClicked}
                handleSave={handleSave}
                handleNext={handleNext}
                handleBack={handleBack}
                handleCloseClicked={handleCloseClicked}
                title={"Add User"}
                open={open}
                hasPrevious={activeStep === 0}
                canSave={activeStep === steps.length - 1}
                stepper={<Stepper activeStep={activeStep}>
                    {steps.map((label) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};

                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>}
            >
                <Box minWidth={600}>
                    {
                        activeStep === 0 &&
                        <NewUserBasicInfoPanel formik={formikBasic} />
                    }{
                        activeStep === 1 &&
                        <NewUserAddressPanel formik={formikAddress} onAddAddress={handleAddress} hasAddressData={hasAddress} />
                    }{
                        activeStep === 2 &&
                        <NewUserContactsPanel />
                    }{
                        activeStep === 3 &&
                        <NewUserApplicationsPanel />
                    }
                    <LoadingBackDrop open={loading} />
                </Box>
            </StepperDialog>

            <CloseConfirmationDialog
                open={closeDialogOpen}
                handleCancel={() => setCloseDialogOpen(false)}
                handleConfirm={handleCloseOk} />

            <MessageDialog
                title="Attention"
                messages={messages}
                handleClose={() => { dispatch(messagesDisplayed([])); }}
            />

            <UserPasswordDialog
                userPwdData={userPasswordData}
                handleClose={handlePasswordDialogClose}
                handleEmailNewUser={handlePasswordDialogEmailNewUser}
            />
        </React.Fragment>
    );
}

export default AddUserDialog;
