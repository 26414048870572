import dayjs from "dayjs";

export const GetShortDateString = (date: Date | null) => {
    if (date === null) {
        return '';
    }
    const newDate = new Date(date);
    return dayjs(newDate).format('MM/DD/YYYY');
}

export const GetLocalDateTimeString = (date: Date | null) => {
    if (date === null) {
        return '';
    }
    const newDate = new Date(date);
    return dayjs(newDate).format('MM/DD/YYYY hh:mm A');
}

export const GetShortTimeString = (date: Date | null) => {
    if (date === null) {
        return '';
    }
    const newDate = new Date(date);
    return dayjs(newDate).format('hh:mm A');
}

export const GetExtraShortTimeString = (date: Date | null) => {
    if (date === null) {
        return '';
    }
    const newDate = new Date(date);
    const djd = dayjs(newDate);
    return `${djd.format('h')}${djd.minute() >= 1 ? `:${djd.format('mm')}` : ''}${djd.format('a')}`;
}
