import apiService from './ApiService';
import {
    ApiViewEntityResponse, ApiViewResponse, ApplicationModel, CountryModel, PaginationRequest, PaginationResponse,
    UserAddressModel, UserContactDataModel, UserDataModel, UserListItemModel, UserModel, CurrentUserModel,
    UserValidationDataModel, UserProfileDataModel, UserProfileResponseModel, AuditLog, AuditLogDetails,
    UserPersonalDetailsModel
} from '../typings';
import { HTTP_METHOD, REACT_APP_STS_AUTHORITY } from "../constants";

class IdentityService {
    async filterUsers(pagination: PaginationRequest<UserListItemModel>): Promise<ApiViewEntityResponse<PaginationResponse<UserListItemModel>>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/actions/filter`, {
            method: HTTP_METHOD.POST,
            body: JSON.stringify(pagination)
        });
        const result: ApiViewEntityResponse<PaginationResponse<UserListItemModel>> = await response.json()
        return result;
    }

    async getCurrentUser(): Promise<ApiViewEntityResponse<CurrentUserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/me`, {
            method: HTTP_METHOD.GET
        });
        const result: ApiViewEntityResponse<CurrentUserModel> = await response.json()
        return result;
    }

    async getCurrentUserApplications(): Promise<ApiViewEntityResponse<ApplicationModel[]>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/me/applications`, {
            method: HTTP_METHOD.GET
        });
        const result: ApiViewEntityResponse<ApplicationModel[]> = await response.json()
        return result;
    }

    async getUser(id: number): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${id}`, {
            method: HTTP_METHOD.GET
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async getCountries(): Promise<ApiViewEntityResponse<CountryModel[]>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/countries`, {
            method: HTTP_METHOD.GET
        });
        const result: ApiViewEntityResponse<CountryModel[]> = await response.json()
        return result;
    }

    async getApplications(): Promise<ApiViewEntityResponse<ApplicationModel[]>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/applications`, {
            method: HTTP_METHOD.GET
        });
        const result: ApiViewEntityResponse<ApplicationModel[]> = await response.json()
        return result;
    }

    async validateUserProfile(data: UserValidationDataModel): Promise<ApiViewResponse> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/profile/actions/validate`, {
            method: HTTP_METHOD.POST,
            body: JSON.stringify(data)
        });
        const result: ApiViewResponse = await response.json()
        return result;
    }

    async createUserProfile(profile: UserDataModel): Promise<ApiViewEntityResponse<UserProfileResponseModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/profile`, {
            method: HTTP_METHOD.POST,
            body: JSON.stringify(profile)
        });
        const result: ApiViewEntityResponse<UserProfileResponseModel> = await response.json()
        return result;
    }

    async updateUserProfile(userId: number, profile: UserProfileDataModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/profile`, {
            method: HTTP_METHOD.PUT,
            body: JSON.stringify(profile)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async updateUserPersonalDetails(userId: number, personalDetails: UserPersonalDetailsModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/personal-details`, {
            method: HTTP_METHOD.PUT,
            body: JSON.stringify(personalDetails)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async createUserAddress(userId: number, userAddress: UserAddressModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/addresses`, {
            method: HTTP_METHOD.POST,
            body: JSON.stringify(userAddress)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async updateUserAddress(userId: number, userAddressId: number, userAddress: UserAddressModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/addresses/${userAddressId}`, {
            method: HTTP_METHOD.PUT,
            body: JSON.stringify(userAddress)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async createUserContact(userId: number, contact: UserContactDataModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/contacts`, {
            method: HTTP_METHOD.POST,
            body: JSON.stringify(contact)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async updateUserContact(userId: number, contactId: number, contact: UserContactDataModel): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/contacts/${contactId}`, {
            method: HTTP_METHOD.PUT,
            body: JSON.stringify(contact)
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async deleteUserContact(userId: number, contactId: number): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/contacts/${contactId}`, {
            method: HTTP_METHOD.DELETE
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async createUserApplicationRelation(userId: number, clientId: number): Promise<ApiViewResponse> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/applications/${clientId}`, {
            method: HTTP_METHOD.POST
        });
        const result: ApiViewResponse = await response.json()
        return result;
    }

    async deleteUserApplicationRelation(userId: number, clientId: number): Promise<ApiViewResponse> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/applications/${clientId}`, {
            method: HTTP_METHOD.DELETE
        });
        const result: ApiViewResponse = await response.json()
        return result;
    }

    async enableUser(userId: number): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/actions/enable`, {
            method: HTTP_METHOD.POST
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async disableUser(userId: number): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/actions/disable`, {
            method: HTTP_METHOD.POST
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async enableMfa(userId: number): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/mfa/actions/enable`, {
            method: HTTP_METHOD.POST
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async disableMfa(userId: number): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/mfa/actions/disable`, {
            method: HTTP_METHOD.POST
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async forcePasswordReset(userId: number): Promise<ApiViewEntityResponse<UserProfileResponseModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/actions/forcePasswordReset`, {
            method: HTTP_METHOD.POST
        });
        const result: ApiViewEntityResponse<UserProfileResponseModel> = await response.json()
        return result;
    }

    async emailUserPassword(userId: number, password: string): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/actions/emailPassword`, {
            method: HTTP_METHOD.POST,
            body: JSON.stringify({ password: password })
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async emailNewUser(userId: number, password: string): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/actions/emailNewUser`, {
            method: HTTP_METHOD.POST,
            body: JSON.stringify({ password: password })
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async updateUserName(userId: number, userName: string): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/userName`, {
            method: HTTP_METHOD.PUT,
            body: JSON.stringify({ userName: userName })
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async updateEmail(userId: number, email: string): Promise<ApiViewEntityResponse<UserModel>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/email`, {
            method: HTTP_METHOD.PUT,
            body: JSON.stringify({ email: email })
        });
        const result: ApiViewEntityResponse<UserModel> = await response.json()
        return result;
    }

    async getAuditLogs(userId: number): Promise<ApiViewEntityResponse<PaginationResponse<AuditLog>>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/audit-logs`, {
            method: HTTP_METHOD.GET
        });
        const result: ApiViewEntityResponse<PaginationResponse<AuditLog>> = await response.json()
        return result;
    }

    async getAuditLogDetails(userId: number, auditLogId: number): Promise<ApiViewEntityResponse<AuditLogDetails>> {
        let response = await apiService.fetch(`${REACT_APP_STS_AUTHORITY}/v1/users/${userId}/audit-logs/${auditLogId}`, {
            method: HTTP_METHOD.GET
        });
        const result: ApiViewEntityResponse<AuditLogDetails> = await response.json()
        return result;
    }
}

const identityService = new IdentityService();
export default identityService;
