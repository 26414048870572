import React, { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import {
    Box, Divider, FormControl, Grid, IconButton, ListItemIcon, ListItemText,
    Menu, MenuItem, Tab, Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PasswordIcon from '@mui/icons-material/Password';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import WarningIcon from '@mui/icons-material/Warning';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    idSelected, getUser, snackDisplayed, messagesDisplayed,
    enableUser, disableUser, forcePasswordReset, emailUserPassword, enableMfa, disableMfa
} from '../../app/userSlice';
import VerticalTabDetailPanel from '@lp/lp-ui/src/components/panels/VerticalTabPanel';
import UserBasicInfoPanel from './UserBasicInfoPanel';
import UserAddressPanel from './UserAddressPanel';
import UserContactsPanel from './UserContactsPanel';
import { BreadcrumbSeparator, LoadingBackDrop, TitleBar, SnackDisplay, MessageDialog, PrimarySaveConfirmationDialog } from '@lp/lp-ui';
import UserApplicationsPanel from './UserApplicationsPanel';
import { useCurrentUser } from '../../app/useCurrentUser';
import { TitleBarLabel } from '@lp/lp-ui/src/components/TitleBar';
import UserPasswordDialog from './UserPasswordDialog';
import { ApiViewEntityResponse, UserPasswordModel, UserProfileResponseModel } from '../../typings';
import { AuditLogDialog } from './AuditLogDialog';

export default function User() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [isAdmin, _] = useCurrentUser();
    const { data, messages, loading, snack } = useAppSelector(store => store.user);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [disableUserDialogOpen, setDisableUserDialogOpen] = useState(false);
    const [disableMfaDialogOpen, setDisableMfaDialogOpen] = useState(false);
    const [resetUserPasswordDialogOpen, setResetUserPasswordDialogOpen] = useState(false);
    const [userPasswordData, setUserPasswordData] = useState<UserPasswordModel | null>(null);
    const [auditLogDialogOpen, setAuditLogDialogOpen] = useState(false);

    useEffect(() => {
        const userId = Number(id);
        dispatch(idSelected(userId));
        dispatch(getUser(userId));
    }, [id]);

    const handleMenuOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenAuditLogDialog = () => {
        setAuditLogDialogOpen(true);
        handleMenuClose();
    }

    const handleUserEnabledChange = (enabled: boolean) => {
        if (enabled) {
            dispatch(enableUser(Number(id)));
        }
        else {
            setDisableUserDialogOpen(true);
        }
    }

    const handleMfaEnabledChange = (enabled: boolean) => {
        if (enabled) {
            dispatch(enableMfa(Number(id)));
        }
        else {
            setDisableMfaDialogOpen(true);
        }
    }

    const handleForcePasswordReset = () => {
        setResetUserPasswordDialogOpen(true);
    }

    const handleDisableUserDialogClose = () => {
        setDisableUserDialogOpen(false);
    }

    const handleDisableMfaDialogClose = () => {
        setDisableMfaDialogOpen(false);
    }

    const handleDisableUserDialogSave = () => {
        handleDisableUserDialogClose();
        dispatch(disableUser(Number(id)));
    }

    const handleDisableMfaDialogSave = () => {
        handleDisableMfaDialogClose();
        dispatch(disableMfa(Number(id)));
    }

    const handleResetUserPasswordDialogClose = () => {
        setResetUserPasswordDialogOpen(false);
    }

    const handleResetUserPasswordDialogSave = async () => {
        handleResetUserPasswordDialogClose();
        const result = await dispatch(forcePasswordReset(Number(id)));
        if (result.meta.requestStatus === 'fulfilled') {
            const userData = result.payload as ApiViewEntityResponse<UserProfileResponseModel>;
            setUserPasswordData(userData.data);
        }
    }

    const handlePasswordDialogClose = () => {
        setUserPasswordData(null);
    }

    const handlePasswordDialogEmailPassword = async () => {
        if (userPasswordData) {
            dispatch(emailUserPassword(userPasswordData));
        }
        handlePasswordDialogClose();
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TitleBar
                        titleComponent={
                            <Box py={1.5}>
                                <Typography variant="h3" color="primary">
                                    <Box display="flex">
                                        <NavLink to="/users">Users</NavLink>
                                        <BreadcrumbSeparator />
                                        {data && `${data.firstName} ${data.lastName}`}
                                    </Box>
                                </Typography>
                            </Box>
                        }
                        centerComponent={
                            <Box className="hidden-xs visible-md" display="flex" justifyContent="flex-end" width="100%" pr={3} sx={{ visibility: { xs: 'hidden', md: 'visible' } }}>
                                <FormControl sx={{ marginRight: 3 }}>
                                    <TitleBarLabel label="Enabled" value={data?.enabled ? "Yes" : "No"} />
                                </FormControl>
                                <FormControl sx={{ marginRight: 3 }}>
                                    <TitleBarLabel label="Force Password Reset" value={data?.forcePasswordReset ? "Yes" : "No"} />
                                </FormControl>
                                <FormControl sx={{ marginRight: 3 }}>
                                    <TitleBarLabel label="MFA Enabled" value={data?.twoFactorEnabled ? "Yes" : "No"} />
                                </FormControl>
                            </Box>
                        }
                        actionComponents={[
                            <IconButton key="title-menu" sx={{ marginLeft: 2, marginRight: -1 }} onClick={handleMenuOpenClick}>
                                <MoreVertIcon />
                            </IconButton>
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <VerticalTabDetailPanel tabs={[
                        {
                            tabComponent: <Tab label="General Information" key="vtp-gi" />,
                            panelComponent: <UserBasicInfoPanel />
                        },
                        {
                            tabComponent: <Tab label="Address" key="vtp-addr" />,
                            panelComponent: <UserAddressPanel />
                        },
                        {
                            tabComponent: <Tab label="Contacts" key="vtp-c" />,
                            panelComponent: <UserContactsPanel />
                        },
                        {
                            tabComponent: <Tab label="Applications" key="vtp-apps" />,
                            panelComponent: <UserApplicationsPanel />
                        }
                    ]} />
                </Grid>
            </Grid>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem disabled={!isAdmin}
                    onClick={() => {
                        handleMenuClose();
                        handleUserEnabledChange(!data?.enabled);
                    }}>
                    <ListItemIcon>
                        {data?.enabled ? (<PersonOffIcon />) : (<PersonIcon />)}
                    </ListItemIcon>
                    <ListItemText>
                        {data?.enabled ? "Disable" : "Enable"}
                    </ListItemText>
                </MenuItem>
                <MenuItem disabled={!isAdmin || !data?.enabled}
                    onClick={() => {
                        handleMenuClose();
                        handleForcePasswordReset();
                    }}>
                    <ListItemIcon>
                        <PasswordIcon />
                    </ListItemIcon>
                    <ListItemText>
                        Reset Password
                    </ListItemText>
                </MenuItem>
                <MenuItem disabled={!isAdmin}
                    onClick={() => {
                        handleMenuClose();
                        handleMfaEnabledChange(!data?.twoFactorEnabled);
                    }}>
                    <ListItemIcon>
                        {data?.twoFactorEnabled ? (<WarningIcon />) : (<SecurityIcon />)}
                    </ListItemIcon>
                    <ListItemText>
                        {data?.twoFactorEnabled ? "MFA Disable" : "MFA Enable"}
                    </ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleOpenAuditLogDialog}>
                    <ListItemIcon>
                        <SpeakerNotesIcon />
                    </ListItemIcon>
                    <ListItemText>
                        View Audit Logs
                    </ListItemText>
                </MenuItem>
            </Menu>

            <MessageDialog
                title="Attention"
                messages={messages}
                handleClose={() => { dispatch(messagesDisplayed([])); }}
            />

            <SnackDisplay
                snack={snack}
                handleClose={() => { dispatch(snackDisplayed(null)); }}
            />

            <PrimarySaveConfirmationDialog
                open={disableUserDialogOpen}
                title={`Disable login for ${data?.firstName} ${data?.lastName}`}
                contentText='The disable action will prevent edits to this user and prevent them from accessing any applications.'
                actionButtonText='Disable'
                handleClose={handleDisableUserDialogClose}
                handleSave={handleDisableUserDialogSave}
            />

            <PrimarySaveConfirmationDialog
                open={resetUserPasswordDialogOpen}
                title={`Reset password for ${data?.firstName} ${data?.lastName}`}
                contentText='A password will automatically be generated. You will be able to view and copy the password in the next step.'
                actionButtonText='Reset'
                handleClose={handleResetUserPasswordDialogClose}
                handleSave={handleResetUserPasswordDialogSave}
            />

            <PrimarySaveConfirmationDialog
                open={disableMfaDialogOpen}
                title={`Disable MFA for ${data?.firstName} ${data?.lastName}`}
                contentText='The disable MFA action will have an impact on the security of the user account.'
                actionButtonText='Disable'
                handleClose={handleDisableMfaDialogClose}
                handleSave={handleDisableMfaDialogSave}
            />

            <UserPasswordDialog
                userPwdData={userPasswordData}
                handleClose={handlePasswordDialogClose}
                handleEmailPassword={handlePasswordDialogEmailPassword}
            />

            <AuditLogDialog
                open={auditLogDialogOpen}
                handleClose={() => { setAuditLogDialogOpen(false); }}
            />

            <LoadingBackDrop open={loading} />
        </React.Fragment>
    );
}
