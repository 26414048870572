import React, { useEffect, useState } from 'react'
import {
    Button, TableBody, TableCell,
    TableHead, TablePagination, TableRow, TableSortLabel,
    DialogActions, DialogContent, Box, DialogTitle, TableFooter,
    IconButton, Grid, Collapse
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DialogTable, StyledDialog, SkeletonTableRows, TextInput } from '@lp/lp-ui';
import { getAuditLogDetails, GetAuditLogDetailsRequest, getAuditLogs } from '../../app/userSlice';
import {
    Direction, AuditLog, AuditLogFields, PaginationRequest, AuditLogDetails, ApiViewEntityResponse, AuditLogType
} from '../../typings';
import { GetLocalDateTimeString } from '../../DateUtilities';

interface PropTypes {
    handleClose: () => void,
    open: boolean
}

export const AuditLogDialog: React.FC<PropTypes> = (props: PropTypes) => {
    const dispatch = useAppDispatch();
    const { id, loadingAuditLogs, auditLogs } = useAppSelector(state => state.user);
    const [auditLogsPage, setAuditLogsPage] = useState<AuditLog[]>([]);
    const [pagination, setPagination] = useState<PaginationRequest<AuditLog>>({
        sort: {
            sortBy: 'timestamp',
            sortDirection: Direction.Descending
        },
        page: {
            pageSize: 10,
            pageNumber: 0
        },
        filterString: ''
    });
    const [auditLogDetails, setAuditLogDetails] = useState<AuditLogDetails | null>(null);

    const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPagination({ ...pagination, page: { pageNumber: newPage, pageSize: pagination.page.pageSize } });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPagination({ ...pagination, page: { pageNumber: 0, pageSize: (parseInt(event.target.value, 10)) } });
    };

    useEffect(() => {
        const start = pagination.page.pageNumber * pagination.page.pageSize;
        const end = (pagination.page.pageNumber + 1) * pagination.page.pageSize;
        const auditLogsClone = Object.assign([], auditLogs?.items ?? []);
        const logsPage = auditLogsClone.sort((a, b) => {
            const result = a[pagination.sort.sortBy] < b[pagination.sort.sortBy] ? -1 : 1;
            return pagination.sort.sortDirection === Direction.Descending ? -result : result;
        })?.slice(start, end);
        setAuditLogsPage(logsPage);
    }, [auditLogs, pagination]);

    useEffect(() => {
        if (props.open) {
            dispatch(getAuditLogs(id));
        }
    }, [id, props.open]);

    const onAuditLogClicked = async (auditLogId: number) => {
        const request: GetAuditLogDetailsRequest = {
            userId: id,
            auditLogId: auditLogId
        };
        const dispatchResult = await dispatch(getAuditLogDetails(request));
        if (dispatchResult.meta.requestStatus === "fulfilled") {
            const logDetails = dispatchResult.payload as ApiViewEntityResponse<AuditLogDetails>;
            setAuditLogDetails(logDetails.data);
        }
    };

    const hasFieldChanges = (auditLogDetails: AuditLogDetails | null): boolean => {
        if (!auditLogDetails) return false;
        if (!auditLogDetails.oldValues && !auditLogDetails.newValues) return false;

        const newValuesKeys = Object.keys(auditLogDetails?.newValues);
        const oldValuesKeys = Object.keys(auditLogDetails?.oldValues);

        return newValuesKeys.length > 0 || oldValuesKeys.length > 0;
    }

    return (
        <React.Fragment>
            <StyledDialog open={props.open} maxWidth={"lg"} fullWidth={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" color="primary" variant="h3">
                    {auditLogDetails !== null &&
                        <IconButton onClick={() => { setAuditLogDetails(null); }}>
                            <ArrowBackIcon />
                        </IconButton>
                    }
                    Audit Logs
                </DialogTitle>
                <DialogContent>
                    <Collapse orientation='vertical' in={auditLogDetails === null}>
                        <Box mx={-5}>
                            <DialogTable>
                                <TableHead>
                                    <TableRow>
                                        {
                                            [
                                                { fieldDef: AuditLogFields[2], align: 'left' },
                                                { fieldDef: AuditLogFields[5], align: 'left' },
                                                { fieldDef: AuditLogFields[6], align: 'left' },
                                                { fieldDef: AuditLogFields[7], align: 'left' }
                                            ].map((obj) => (
                                                <TableCell
                                                    key={obj.fieldDef.fieldName}
                                                    align={obj.align as "left" | "center" | "right" | "justify" | "inherit" | undefined}
                                                    sx={{fontWeight: "bold"}}
                                                >
                                                    <TableSortLabel
                                                        active={pagination.sort.sortBy === (obj.fieldDef.fieldName)}
                                                        direction={pagination.sort.sortDirection === Direction.Ascending
                                                            ? "asc"
                                                            : "desc"}
                                                        onClick={() => {
                                                            setPagination({
                                                                ...pagination,
                                                                sort: {
                                                                    sortBy: obj.fieldDef.fieldName as keyof AuditLog,
                                                                    sortDirection: pagination.sort.sortBy === obj.fieldDef.fieldName
                                                                        ? (
                                                                            pagination.sort.sortDirection === Direction.Ascending
                                                                                ? Direction.Descending
                                                                                : Direction.Ascending
                                                                        )
                                                                        : pagination.sort.sortDirection
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        {obj.fieldDef.displayName}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))
                                        }
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        loadingAuditLogs
                                            ?
                                            <SkeletonTableRows rows={10} columns={5} />
                                            :
                                            auditLogsPage.map((row) => (
                                                <TableRow key={`row-id-${row.id}`} hover>
                                                    <TableCell>{row.entityType.split(/(?=[A-Z])/).join(" ")}</TableCell>
                                                    <TableCell>{`${row.user.firstName} ${row.user.lastName}`}</TableCell>
                                                    <TableCell>{row.actionLabel}</TableCell>
                                                    <TableCell>{GetLocalDateTimeString(new Date(row.timestamp))}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton size="large"
                                                            onClick={() => { onAuditLogClicked(row.id); }}
                                                        >
                                                            <NavigateNextIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                    }
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            count={auditLogs?.totalCount}
                                            rowsPerPage={pagination.page.pageSize}
                                            page={pagination.page.pageNumber}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </DialogTable>
                        </Box>
                    </Collapse>
                    <Collapse orientation='vertical' in={auditLogDetails !== null}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextInput
                                    isEditing={false}
                                    type="string"
                                    label={AuditLogFields[2].displayName}
                                    value={auditLogDetails?.entityType.split(/(?=[A-Z])/).join(" ")}
                                    name={AuditLogFields[2].fieldName}
                                    error={false}
                                    helperText=""
                                    onChange={() => { }}
                                    onBlur={() => { }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextInput
                                    isEditing={false}
                                    type="string"
                                    label={AuditLogFields[5].displayName}
                                    value={`${auditLogDetails?.user.firstName} ${auditLogDetails?.user.lastName}`}
                                    name={AuditLogFields[5].fieldName}
                                    error={false}
                                    helperText=""
                                    onChange={() => { }}
                                    onBlur={() => { }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextInput
                                    isEditing={false}
                                    type="string"
                                    label={AuditLogFields[6].displayName}
                                    value={auditLogDetails?.actionLabel}
                                    name={AuditLogFields[6].fieldName}
                                    error={false}
                                    helperText=""
                                    onChange={() => { }}
                                    onBlur={() => { }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextInput
                                    isEditing={false}
                                    type="string"
                                    label={AuditLogFields[7].displayName}
                                    value={GetLocalDateTimeString(new Date(auditLogDetails?.timestamp || ""))}
                                    name={AuditLogFields[7].fieldName}
                                    error={false}
                                    helperText=""
                                    onChange={() => { }}
                                    onBlur={() => { }}
                                />
                            </Grid>
                        </Grid>
                        {hasFieldChanges(auditLogDetails) &&
                            <Box mx={-5} mt={3}>
                                <DialogTable>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{fontWeight: "bold"}}>Field</TableCell>
                                            <TableCell sx={{fontWeight: "bold"}}>Old Value</TableCell>
                                            <TableCell sx={{fontWeight: "bold"}}>New Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            auditLogDetails?.action === AuditLogType.Create
                                                ? Object.keys(auditLogDetails?.newValues).map((key) => (
                                                    <TableRow key={`audit-row-id-${key}`} hover>
                                                        <TableCell>{key}</TableCell>
                                                        <TableCell>N/A</TableCell>
                                                        <TableCell>{`${auditLogDetails?.newValues[key]}`}</TableCell>
                                                    </TableRow>
                                                ))
                                                : Object.keys(auditLogDetails?.oldValues || {}).map((key) => (
                                                    <TableRow key={`audit-row-id-${key}`} hover>
                                                        <TableCell>{key}</TableCell>
                                                        <TableCell>{`${auditLogDetails?.oldValues[key]}`}</TableCell>
                                                        <TableCell>{auditLogDetails?.action === AuditLogType.Delete ? "N/A" : `${auditLogDetails?.newValues[key]}`}</TableCell>
                                                    </TableRow>
                                                ))
                                        }
                                    </TableBody>
                                </DialogTable>
                            </Box>
                        }
                    </Collapse>
                </DialogContent>
                <DialogActions>
                    {auditLogDetails !== null &&
                        <Button variant="outlined" onClick={() => { setAuditLogDetails(null); }}>BACK</Button>
                    }
                    <Box flexGrow={1} />
                    <Button size="small"
                        onClick={() => { setAuditLogDetails(null); props.handleClose(); }}
                        color="secondary"
                        variant="text"
                    >
                        Close
                    </Button>
                </DialogActions>
            </StyledDialog>
        </React.Fragment>
    )
}