import React, { useEffect, useState } from 'react';
import { useTheme, Box, Grid, Typography, IconButton } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { createUserAddress, CreateUserAddressRequest, updateUserAddress, UpdateUserAddressRequest } from '../../app/userSlice';
import { AddressModel, AddressType, UserAddressModel } from '../../typings';
import { AddressSchema } from '../../validationSchemes';
import { TextInput, AutoCompleteInput, EditIcon, CancelIcon, SaveIcon } from '@lp/lp-ui';
import useCache from '../../app/useCache';
import { CachedEntity, CacheLookup } from '../../app/cacheSlice';
import { NewAddressModel } from '../../functions';
import { useCurrentUser } from '../../app/useCurrentUser';

export default function UserAddressPanel() {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const userState = useAppSelector(store => store.user);
    const countries = useCache(CachedEntity.Country);
    const [isEditing, setIsEditing] = useState(false);
    const [isAdmin, _] = useCurrentUser();

    useEffect(() => {
        if (!userState.data?.enabled) {
            setIsEditing(false);
        }
    }, [userState.data]);

    const formik = useFormik<AddressModel>({
        initialValues: userState.data !== null && userState.data.userAddresses.length > 0 ? userState.data.userAddresses[0].address : NewAddressModel(),
        enableReinitialize: true,
        validationSchema: AddressSchema,
        onSubmit: async (values) => {
            if (!formik.dirty) {
                setIsEditing(false);
                return true;
            }

            const userAddress = userState?.data?.userAddresses[0];
            const userAddressId = userAddress?.id ?? 0;

            const userAddressData: UserAddressModel = {
                id: userAddressId,
                addressType: userAddress?.addressType ?? AddressType.Default,
                address: values
            };

            if (userAddressId === 0) {
                const request: CreateUserAddressRequest = {
                    userId: Number(userState.id),
                    userAddress: userAddressData
                };
                const result = await dispatch(createUserAddress(request));
                const success = result.meta.requestStatus === 'fulfilled';
                if (success) {
                    setIsEditing(false);
                }
                return success;
            }
            else {
                const request: UpdateUserAddressRequest = {
                    userId: Number(userState.id),
                    userAddressId: Number(userAddress?.id),
                    userAddress: userAddressData
                };
                const result = await dispatch(updateUserAddress(request));
                const success = result.meta.requestStatus === 'fulfilled';
                if (success) {
                    setIsEditing(false);
                }
                return success;
            }
        }
    });

    const {
        errors,
        touched,
        getFieldProps,
        submitForm
    } = formik;

    const cancelEditing = () => {
        formik.resetForm();
        setIsEditing(false);
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={submitForm} >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                                <Typography variant="h5" color="primary">
                                    Address
                                </Typography>
                                <Box mr={-1}>
                                    {isEditing
                                        ?
                                        <React.Fragment>
                                            <IconButton id="cancel-icon-btn" onClick={cancelEditing} size="small">
                                                <CancelIcon />
                                            </IconButton>
                                            <IconButton onClick={submitForm} size="small">
                                                <SaveIcon />
                                            </IconButton>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {
                                                (isAdmin && (userState?.data?.enabled ?? false)) &&
                                                <IconButton
                                                    size="small" id="edit-icon-btn" color='inherit'
                                                    onClick={() => { setIsEditing(true); }}
                                                    sx={{ color: theme.palette.grey[600] }}
                                                    disableRipple
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            }
                                        </React.Fragment>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0} columnSpacing={6}>
                                <Grid item xs={12}>
                                    <TextInput
                                        isEditing={isEditing}
                                        type="string"
                                        label="Street 1"
                                        required
                                        {...getFieldProps("street1")}
                                        error={Boolean(touched.street1 && errors.street1)}
                                        helperText={touched.street1 && errors.street1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        isEditing={isEditing}
                                        type="string"
                                        label="Street 2"
                                        {...getFieldProps("street2")}
                                        error={Boolean(touched.street2 && errors.street2)}
                                        helperText={touched.street2 && errors.street2}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        isEditing={isEditing}
                                        type="string"
                                        label="City"
                                        required
                                        {...getFieldProps("city")}
                                        error={Boolean(touched.city && errors.city)}
                                        helperText={touched.city && errors.city}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        isEditing={isEditing}
                                        type="string"
                                        label="State"
                                        required
                                        {...getFieldProps("state")}
                                        error={Boolean(touched.state && errors.state)}
                                        helperText={touched.state && errors.state}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        isEditing={isEditing}
                                        type="string"
                                        label="Postal Code"
                                        required
                                        {...getFieldProps("postalCode")}
                                        error={Boolean(touched.postalCode && errors.postalCode)}
                                        helperText={touched.postalCode && errors.postalCode}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AutoCompleteInput
                                        getOptionLabel={(option: CacheLookup) => `${option.label}`}
                                        renderOption={(props, option: CacheLookup) => (
                                            <Box component="li" {...props}>
                                                {option.label}
                                            </Box>
                                        )}
                                        options={countries}
                                        isEditing={isEditing}
                                        label="Country"
                                        required
                                        error={Boolean(touched.countryAlpha3Code && errors.countryAlpha3Code)}
                                        helperText={touched.countryAlpha3Code && errors.countryAlpha3Code}
                                        {...formik.getFieldProps("countryAlpha3Code")}
                                        value={countries.find(p => p.key === formik.values.countryAlpha3Code) || null}
                                        onChange={(e, v: CacheLookup | null) => { formik.setFieldValue("countryAlpha3Code", v?.key || null) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}
