import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import usersReducer from './usersSlice'
import userReducer from './userSlice'
import cacheReducer from './cacheSlice';
import currentUserReducer from './currentUserSlice';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['']
};

const rootReducer = combineReducers({
  users: usersReducer,
  user: userReducer,
  genericCache: cacheReducer,
  currentUser: currentUserReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    })
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
