import React, { useState } from 'react';
import { Box, Collapse, Grid, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Form, FormikProvider, FormikProps } from 'formik';
import { AddressModel } from '../../typings';
import { TextInput, AutoCompleteInput, DialogTableTitle } from '@lp/lp-ui';
import useCache from '../../app/useCache';
import { CachedEntity, CacheLookup } from '../../app/cacheSlice';
import { useCurrentUser } from '../../app/useCurrentUser';

interface AddressPanelProps {
    formik: FormikProps<AddressModel>,
    onAddAddress: (hasAddress: boolean) => void,
    hasAddressData: boolean
}

const NewUserAddressPanel: React.FC<AddressPanelProps> = ({ formik, onAddAddress, hasAddressData }) => {
    const countries = useCache(CachedEntity.Country);
    const [isAdmin, _] = useCurrentUser();
    const [addAddress, setAddAddress] = useState(hasAddressData);

    const {
        errors,
        touched,
        getFieldProps,
        submitForm
    } = formik;

    const handleAddAddress = (hasAddress: boolean) => {
        setAddAddress(hasAddress);
        onAddAddress(hasAddress);
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={submitForm}>
                    <Box mx={-5}>
                        <DialogTableTitle title="Address">
                            <React.Fragment>
                                {!addAddress && isAdmin &&
                                    <IconButton size="large"
                                        onClick={() => { handleAddAddress(true); }}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                }
                                {addAddress && isAdmin &&
                                    <IconButton size="large"
                                        onClick={() => { handleAddAddress(false); }}
                                    >
                                        <ExpandLessIcon />
                                    </IconButton>
                                }
                            </React.Fragment>
                        </DialogTableTitle>
                    </Box>
                    <Collapse in={addAddress}>
                        <Grid container spacing={1} columnSpacing={6} mt={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    isEditing={isAdmin}
                                    type="string"
                                    label="Street 1"
                                    required
                                    {...getFieldProps("street1")}
                                    error={Boolean(touched.street1 && errors.street1)}
                                    helperText={touched.street1 && errors.street1}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    isEditing={isAdmin}
                                    type="string"
                                    label="Street 2"
                                    {...getFieldProps("street2")}
                                    error={Boolean(touched.street2 && errors.street2)}
                                    helperText={touched.street2 && errors.street2}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    isEditing={isAdmin}
                                    type="string"
                                    label="City"
                                    required
                                    {...getFieldProps("city")}
                                    error={Boolean(touched.city && errors.city)}
                                    helperText={touched.city && errors.city}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    isEditing={isAdmin}
                                    type="string"
                                    label="State"
                                    required
                                    {...getFieldProps("state")}
                                    error={Boolean(touched.state && errors.state)}
                                    helperText={touched.state && errors.state}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    isEditing={isAdmin}
                                    type="string"
                                    label="Postal Code"
                                    required
                                    {...getFieldProps("postalCode")}
                                    error={Boolean(touched.postalCode && errors.postalCode)}
                                    helperText={touched.postalCode && errors.postalCode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AutoCompleteInput
                                    getOptionLabel={(option: CacheLookup) => `${option.label}`}
                                    renderOption={(props, option: CacheLookup) => (
                                        <Box component="li" {...props}>
                                            {option.label}
                                        </Box>
                                    )}
                                    options={countries}
                                    isEditing={isAdmin}
                                    label="Country"
                                    required
                                    error={Boolean(touched.countryAlpha3Code && errors.countryAlpha3Code)}
                                    helperText={touched.countryAlpha3Code && errors.countryAlpha3Code}
                                    {...formik.getFieldProps("countryAlpha3Code")}
                                    value={countries.find(p => p.key === formik.values.countryAlpha3Code) || null}
                                    onChange={(e, v: CacheLookup | null) => { formik.setFieldValue("countryAlpha3Code", v?.key || null) }}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}

export default NewUserAddressPanel;
