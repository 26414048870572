import {
    AddressModel, AddressType, ContactType, UserAddressModel,
    UserContactModel, UserDataModel, UserProfileDataModel
} from "./typings";

export const NewAddressModel: () => AddressModel = () => ({
    id: 0,
    street1: "",
    street2: "",
    city: "",
    state: "",
    postalCode: "",
    countryAlpha3Code: ""
});

export const NewUserAddressModel: () => UserAddressModel = () => ({
    id: 0,
    addressType: AddressType.Default,
    address: NewAddressModel()
});

export const NewUserProfileDataModel: () => UserProfileDataModel = () => ({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    enabled: true,
    twoFactorEnabled: false,
    forcePasswordReset: false
});

export const NewUserContactModel: () => UserContactModel = () => ({
    id: 0,
    contactType: ContactType.Undefined,
    contact: "",
    phoneExtension: ""
});

export const NewUserDataModel: () => UserDataModel = () => ({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    enabled: true,
    twoFactorEnabled: false,
    forcePasswordReset: false,
    userAddresses: [NewUserAddressModel()],
    contacts: [],
    userClients: []
});

export const ShortenStringIfLength = (string: string, length: number) => {
    return string.length > length ? (string.substring(0, length-1) + '...'): string
}
