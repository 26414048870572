import React, { useState } from 'react';
import {
    TableHead, TableRow, TableCell, IconButton, TableBody, Collapse, Menu, MenuItem, Box
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ContactType, ContactTypeLabels, UserContactModel } from '../../typings';
import { DeleteConfirmationDialog, DialogTable, DialogTableTitle } from '@lp/lp-ui';
import { NewUserContactModel } from '../../functions';
import ContactPanel from './ContactPanel';
import { userDataSelected } from '../../app/userSlice';
import { useCurrentUser } from '../../app/useCurrentUser';

export default function NewUserContactsPanel() {
    const dispatch = useAppDispatch();
    const { data } = useAppSelector(store => store.user);
    const [contactData, setContactData] = useState<UserContactModel>(NewUserContactModel());
    const [newEditOpen, setNewEditOpen] = useState(false);
    const [deleteContactDialogOpen, setDeleteContactDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [isAdmin, _] = useCurrentUser();

    const handleContactPanelClose = () => {
        setNewEditOpen(false);
        setAnchorEl(null);
    };

    const handleMenuOpenClick = (event: React.MouseEvent<HTMLButtonElement>, unit: UserContactModel) => {
        setAnchorEl(event.currentTarget);
        setContactData(unit);
    };

    const handleContactDeleteOk = () => {
        handleMenuClose();
        if (contactData && data !== null) {
            const index = data.contacts.indexOf(contactData);
            if (index !== undefined && index > -1) {
                const userContactsClone = Object.assign([], data.contacts);
                userContactsClone.splice(index, 1);
                dispatch(userDataSelected({ ...data, contacts: userContactsClone }));
            }
        }
        setContactData(NewUserContactModel());
        setDeleteContactDialogOpen(false);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box mx={-5}>
                <DialogTableTitle title="Contacts">
                    <React.Fragment>
                        {!newEditOpen && isAdmin &&
                            <IconButton size="large"
                                onClick={() => { setContactData(NewUserContactModel()); setNewEditOpen(true); }}
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        }
                    </React.Fragment>
                </DialogTableTitle>

                <Collapse in={newEditOpen} sx={{ border: '1px solid lightgrey' }}>
                    <ContactPanel
                        data={contactData}
                        handleClose={handleContactPanelClose}
                    />
                </Collapse>
                <Collapse in={!newEditOpen}>
                    {data?.contacts !== undefined && data?.contacts.length > 0 &&
                        <DialogTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Contact Type</TableCell>
                                    <TableCell>Contact</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.contacts.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {ContactTypeLabels.find(l => l.value === row.contactType)?.label}
                                        </TableCell>
                                        <TableCell>
                                            {`${row.contact}${(row.contactType === ContactType.Phone && row.phoneExtension ? `x${row.phoneExtension}` : "")}`}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                aria-label="delete"
                                                size="small"
                                                color="primary"
                                                onClick={(e) => handleMenuOpenClick(e, row)}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </DialogTable>
                    }
                </Collapse>
            </Box>

            <DeleteConfirmationDialog
                title="Are you sure you&apos;d like to delete this Contact?"
                open={deleteContactDialogOpen}
                handleClose={() => { setDeleteContactDialogOpen(false); setAnchorEl(null); }}
                handleSave={handleContactDeleteOk}
            />

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => { setNewEditOpen(true); handleMenuClose(); }}>Edit</MenuItem>
                <MenuItem onClick={() => setDeleteContactDialogOpen(true)}>Delete</MenuItem>
            </Menu>
        </React.Fragment>
    );
}
