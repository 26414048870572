import React from 'react';
import { Box, Typography, Link, Stack } from '@mui/material';

export default function Footer(props: any) {
    return (
        <React.Fragment>
            <Box {...props} textAlign="center">
                <Stack spacing={1}>
                    <Typography variant="body2">
                        <Link href="https://www.logisticsplus.com/about-us/company-profile/terms-conditions/website-terms-of-use/" target="_blank" m={1}>LEGAL</Link>
                        <Link href="https://www.logisticsplus.com/about-us/company-profile/terms-conditions/privacy-policy/" target="_blank" m={1}>PRIVACY</Link>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {'© '}{new Date().getFullYear()}{' '}
                        <Link color="inherit" href="https://www.logisticsplus.com" target="_blank">Logistics Plus Inc.</Link>
                        {' All rights reserved.'}
                    </Typography>
                </Stack>
            </Box>
        </React.Fragment>
    );
}
