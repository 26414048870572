import React, { useState } from 'react';
import {
    Grid, TableContainer, Typography, Box, TableHead, TableRow, TableCell, IconButton, TableBody
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAppSelector } from '../../app/hooks'
import { ContactType, ContactTypeLabels, UserContactModel } from '../../typings';
import ContactDialog from './ContactDialog';
import { StyledDetailTable } from '@lp/lp-ui';
import { NewUserContactModel } from '../../functions';
import { useCurrentUser } from '../../app/useCurrentUser';

export default function UserContactsPanel() {
    const userState = useAppSelector(store => store.user);
    const [contactDialogOpen, setContactDialogOpen] = useState<boolean>(false);
    const [contactData, setContactData] = useState<UserContactModel>(NewUserContactModel());
    const [isAdmin, _] = useCurrentUser();

    const handleContactDialogClose = () => {
        setContactDialogOpen(false);
    };

    return (
        <React.Fragment>
            <Grid container spacing={0} columnSpacing={6}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                        Contacts
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Box}>
                        <StyledDetailTable size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Contact Type</TableCell>
                                    <TableCell>Contact</TableCell>
                                    <TableCell align="right">
                                        {(isAdmin && (userState?.data?.enabled ?? false)) &&
                                            <IconButton size="large"
                                                onClick={() => {
                                                    setContactData(NewUserContactModel());
                                                    setContactDialogOpen(true);
                                                }}
                                            >
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userState.data?.contacts.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>
                                            {ContactTypeLabels.find(l => l.value === row.contactType)?.label}
                                        </TableCell>
                                        <TableCell>
                                            {`${row.contact}${(row.contactType === ContactType.Phone && row.phoneExtension ? `x${row.phoneExtension}` : "")}`}
                                        </TableCell>
                                        <TableCell align="right">
                                            {(isAdmin && (userState?.data?.enabled ?? false)) &&
                                                <IconButton size="large"
                                                    onClick={() => {
                                                        setContactData(row);
                                                        setContactDialogOpen(true);
                                                    }}
                                                >
                                                    <NavigateNextIcon />
                                                </IconButton>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledDetailTable>
                    </TableContainer>
                </Grid>
            </Grid>

            <ContactDialog
                open={contactDialogOpen}
                data={contactData}
                handleClose={handleContactDialogClose}
            />
        </React.Fragment>
    );
}
